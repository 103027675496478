/* eslint-disable no-undef */
import axios from '../config/axiosInterceptor';

export const logout = async (token) => {
  try {
    if (token) {
      await axios.post('/auth/logout');
    }
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
  }
  localStorage.removeItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY);
  localStorage.removeItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_KEY);

  window.location.replace('/login');
};
