import PropTypes from 'prop-types';
import PermeabilityStats from '../../../containers/stats/PermeabilityStats';

export const PermeabilityResults = ({ stats }) => {
  return (
    <section id="PermeabilityResults" className="mt-10 py-10">
      <p className="uppercase text-primary font-bold text-3xl">Índice de permeabilidad</p>
      <br />
      <p className="font-semibold">
        ¿Qué tan permeable en la cultura de tu organización a la Diversidad, Equidad e Inclusión?
      </p>
      <br />
      <p>
        La permeabilidad de la cultura organizacional a DEI es un indicador que muestra si DEI se ha
        transformado o puede transformarse en un ADN de la cultura de tu organización.
      </p>
      <br />
      <p className="font-semibold">
        El índice de permeabilidad se presenta en un rango de datos de -300 a +300, siendo -300
        IMPERMEABLE y el 300 PERMEABLE.
      </p>
      <br />
      <br />
      <br />
      <div className="bg-gray-100 border-gray-500 rounded-md border-2">
        <div className="md:w-1/2 py-5 px-8 h-full m-auto">
          <p className="text-primary p-3">
            Es importante que la interpretación del resultado se acompañe con la interpretación
            individual de los juegos para obtener conclusiones y diseñar acciones que ayuden a una
            mayor permeabilidad de la organización.
          </p>
        </div>
      </div>

      <PermeabilityStats data={stats.indicePermeabilidad} />
    </section>
  );
};

PermeabilityResults.propTypes = {
  stats: PropTypes.object
};
