import axios from '../../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Modal, Input, Pagination, Notification, Button } from '../../../components';
import AddAdminUser from './AddAdminUser';

import plusIcon from '../../../assets/icons/plus.svg';
import arrowIcon from '../../../assets/icons/arrow.svg';
import noDataIllustration from '../../../assets/icons/noData.svg';
import { useDispatch } from 'react-redux';
import { setUserCompany } from '../../../store/slices/userSlice';

const AdminUsers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [adminUsersData, setAdminUsersData] = useState({
    companyName: '',
    count: 0,
    users: []
  });
  const [usersPerPage, setUsersPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchEmail, setSearchEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const [emailToDelete, setEmailToDelete] = useState('');
  const [userId, setUserId] = useState('');
  const [successCreatingUser, setSuccessCreatingUser] = useState(null);
  const [successDeletingUser, setSuccessDeletingUser] = useState(null);
  const [apiError, setApiError] = useState(null);

  const { pathname } = useLocation();
  const companyId = pathname.split('/')[2];
  dispatch(setUserCompany(companyId));

  const lastUserIndex = currentPage * usersPerPage;
  const firstUserIndex = lastUserIndex - usersPerPage;
  const currentUsers = adminUsersData.users.slice(firstUserIndex, lastUserIndex);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const userToDelete = (email, id) => {
    setOpenDeleteModal(!openDeleteModal);
    setUserId(id);
    setEmailToDelete(email);
  };

  const fetchAdminUsers = async () => {
    try {
      const res = await axios.get(
        `/admin/adminUsers/${companyId}?search=${searchEmail}&limit=${usersPerPage}&page${currentPage}`
      );
      setAdminUsersData(res.data);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    }
  };

  const deleteAdminUser = async (id) => {
    setLoading(true);
    try {
      await axios.delete(`/admin/adminUsers/${id}`);
      setLoading(false);
      setSuccessDeletingUser(true);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setApiError(error.response.data.message || error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAdminUsers();

    if (successCreatingUser) {
      const timer = setTimeout(() => {
        setSuccessCreatingUser(null);
      }, 3000);
      return () => clearTimeout(timer);
    }

    if (successDeletingUser) {
      toggleDeleteModal();
      const timer = setTimeout(() => {
        setSuccessDeletingUser(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [searchEmail, isOpen, successCreatingUser, successDeletingUser]);

  return (
    <>
      <div className="flex w-full flex-col mt-12 px-8">
        <div className="flex flex-col mb-7 sm:flex-row w-full justify-between">
          <button
            className="text-secondary flex px-6 py-3 w-56 items-center rounded-md border-2 mb-4 border-secondary"
            onClick={() => navigate(-1)}>
            <img src={arrowIcon} alt="add" draggable={false} className="mr-2" />
            <span>Volver a empresas</span>
          </button>

          <button
            onClick={toggleModal}
            className="px-6 py-3 rounded-md bg-green_btn min-w-56 max-w-72 text-sm sm:text-md sm:mb-4 items-center text-white flex">
            <img src={plusIcon} alt="add" draggable={false} className="mr-2" />
            <span>Agregar usuario administrador</span>
          </button>
        </div>
        <p className="text-md sm:text-xl  font-semibold my-3">
          Usuarios administradores{' '}
          <span className="text-secondary ">{adminUsersData.companyName}</span>
        </p>

        <div className="w-full">
          {' '}
          <Input
            showCrossIcon
            type="text"
            value={searchEmail}
            onFocus={() => setApiError(null)}
            onIconClick={() => setSearchEmail('')}
            onChange={(e) => setSearchEmail(e.target.value)}
            placeholder="Buscar por correo electrónico..."
          />
          {successCreatingUser !== null ? (
            <Notification type="success" message="El usuario fue agregado exitosamente" />
          ) : successDeletingUser ? (
            <Notification type="success" message="El usuario fue eliminado exitosamente" />
          ) : null}
          {adminUsersData.users.length > 0 ? (
            <>
              <div className="rounded-lg border">
                <table className="table-fixed w-full border-tertiary">
                  <thead>
                    <tr className="bg-[#FAFAFA] sm:rounded-t-lg hidden sm:grid grid-cols-3">
                      <th className="p-6 text-left  text-sm font-medium border-r ">
                        Correo electrónico
                      </th>
                      <th className="p-6  text-left text-sm font-medium border-r">Nombre</th>
                      <th className="p-6 text-left text-sm font-medium ">Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    {adminUsersData.users.length > 0
                      ? currentUsers.map((user, index) => (
                          <tr
                            key={user._id}
                            className="grid grid-cols-1 sm:rounded-b-lg sm:grid-cols-3">
                            <td
                              className={`sm:border-r ${
                                index === 0
                                  ? 'rounded-t-lg '
                                  : index === currentUsers.length - 1
                                  ? 'rounded-b-lg'
                                  : ''
                              } p-6 pl-3 w-full truncate bg-light_gray sm:bg-white sm:p-6 text-sm`}>
                              {user.email}
                            </td>
                            <td className="p-3 sm:p-6 text-sm sm:border-r">
                              <p>{user.name}</p>
                            </td>
                            <td
                              className={`${
                                index === currentUsers.length - 1
                                  ? 'rounded-b-lg sm:rounded-none'
                                  : ''
                              } p-3 sm:p-6 text-sm text-red-500`}>
                              <button onClick={() => userToDelete(user.email, user._id)}>
                                Eliminar usuario
                              </button>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>
              <Pagination
                itemsPerPage={usersPerPage}
                totalResults={adminUsersData.users.length}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage}
                setResultsPerPage={setUsersPerPage}
              />
            </>
          ) : (
            <div className="flex my-4 flex-col items-center justify-center">
              <img className="h-24 w-24" src={noDataIllustration} alt="no data" draggable={false} />
              <p className="text-xl text-center">No se encontraron usuarios administradores</p>
            </div>
          )}{' '}
        </div>
      </div>
      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <AddAdminUser toggleModal={toggleModal} setSuccessCreatingUser={setSuccessCreatingUser} />
      </Modal>

      <Modal isOpen={openDeleteModal} toggleModal={toggleDeleteModal}>
        <p className="text-secondary text-lg mb-7 font-semibold">Eliminar usuario administrador</p>
        <p>
          ¿Estás seguro que quieres eliminar a{' '}
          {emailToDelete ? <span className="text-secondary">{emailToDelete}</span> : 'este usuario'}
          ?
          <br />
          Esta acción no se puede deshacer.
        </p>
        {apiError && <Notification type="error" message={apiError} />}
        <div className="flex flex-col items-center justify-center gap-x-5 sm:flex-row">
          <Button
            mb="mb-2 "
            disabled={loading}
            loading={loading}
            onClick={() => deleteAdminUser(userId)}
            variant="danger">
            Si, eliminar
          </Button>
          <Button mt="mt-4 sm:mt-8" onClick={toggleDeleteModal}>
            Cancelar
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default AdminUsers;
