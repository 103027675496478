import React from 'react';
import PropTypes from 'prop-types';
import AddMultipleParticipantsRow from './participants/AddMultipleParticipantsRow.js';

// eslint-disable-next-line react/prop-types
const Table = ({ cols, data }) => {
  return (
    <div className="rounded-lg  border border-b-none">
      <table className="table-fixed  w-full ">
        <thead>
          <tr className={`hidden sm:grid sm:grid-cols-${cols.length} sm:w-full`}>
            {cols.map((col, index) => (
              <th
                className={`p-6 text-left bg-light_gray text-sm sm:col-span-1 font-medium border-r ${
                  index === 0 ? 'rounded-tl-lg' : index === cols.length - 1 ? 'rounded-tr-lg' : ''
                }`}
                key={col}>
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((row, index) => (
            <AddMultipleParticipantsRow
              email={row.email}
              key={index}
              index={index}
              totalRows={data.length - 1}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;

Table.propTypes = {
  cols: PropTypes.arrayOf(PropTypes.string).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  error: PropTypes.string
};
