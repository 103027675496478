/* eslint-disable no-undef */
import React, { useEffect, useState } from 'react';
import logo from '../../assets/img/logo.png';
import { Button, Notification, Input } from '../../components';
import { isValidEmail } from '../../utils/validators';
import axios from '../../config/axiosInterceptor';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(null);

  const handleSubmitEmail = async () => {
    try {
      setLoading(true);
      const res = await axios.post('/auth/byEmailPasswordReset', {
        email
      });
      setLoading(false);
      if (res.status === 200) {
        setSuccess(res.data.message);
      }
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setApiError(error.response.data.error || error.message);
      setLoading(false);
    }
  };

  const emailIsValid = () => {
    return !error && email.trim() !== '' && !loading && !success;
  };

  useEffect(() => {
    if (apiError) {
      const timer = setTimeout(() => {
        setApiError(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [apiError]);

  return (
    <div className="h-screen w-screen flex flex-col justify-between">
      <header>
        <img src={logo} draggable={false} className="w-56 block mb-12 mt-4 mx-auto " alt="logo" />
        <p className="w-5/6 sm:w-4/6 mx-auto text-center">
          Reestablece tu contraseña enviando el correo electrónico registrado en Humanin Lab y sigue
          las instrucciones.
        </p>
      </header>
      <main className="my-4 gap-y-6 w-auto mx-4 sm:w-2/3 sm:mx-auto  ">
        <Input
          type="email"
          label="Correo electrónico *"
          name="email"
          disabled={success}
          onChange={(e) => {
            setEmail(e.target.value);
            !isValidEmail(e.target.value) ? setError('Email invalido') : setError(null);
          }}
          showValidIcon={isValidEmail(email)}
          errors={error}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && emailIsValid()) {
              handleSubmitEmail(e);
            }
          }}
        />
        {apiError ? (
          <Notification type="error" message={apiError} />
        ) : success ? (
          <Notification type="success" message={`Por favor revisa tu Email y sigue el link`} />
        ) : null}
        <Button
          disabled={!emailIsValid()}
          onClick={handleSubmitEmail}
          mx="mx-auto"
          loading={loading}>
          Restablecer contraseña
        </Button>
      </main>
      <footer className="mb-4">
        <p className="text-center">¿Tienes una consulta?</p>
        <a
          className="text-secondary block text-center hover:underline"
          href="mailto:clientes@humaninlab.com">
          clientes@humaninlab.com
        </a>
      </footer>
    </div>
  );
};

export default ResetPassword;
