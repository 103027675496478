import { Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import cx from 'classnames';

export const statsSections = [
  {
    html: 'Composición de<br/>la muestra',
    label: 'Composición de la muestra',
    href: '#Composition'
  },
  {
    html: 'Resultados Juego<br/>de Respuesta Automática',
    label: 'Resultados Juego de Respuesta Automática',
    href: '#AutoReplyResults'
  },
  {
    html: 'Resultados Juego<br/>de Palabras',
    label: 'Resultados Juego de Palabras',
    href: '#WordPlayResults'
  },
  {
    html: 'Indice de<br/>permeabilidad',
    label: 'Indice de permeabilidad',
    href: '#PermeabilityResults'
  }
];

export const StatsNavbar = () => {
  const location = useLocation();

  if (!location.pathname.startsWith('/stats')) {
    return null;
  }

  return (
    <div
      className="md:flex font-base fixed text-white uppercase z-10 right-0 mr-5 h-16 hidden"
      style={{ top: `87px` }}>
      {statsSections.map((section, i) => {
        const isActive = location.hash.includes(section.href);
        return (
          <Fragment key={section.href}>
            {i !== 0 && <div className="h-full border border-slate-500"></div>}
            <Link className="relative" to={`/stats/${section.href}`}>
              {isActive && <div className="absolute w-full border-2"></div>}
              <div
                className={cx([
                  'py-2 px-4',
                  {
                    'font-semibold': isActive
                  }
                ])}
                key={section.href}
                dangerouslySetInnerHTML={{ __html: section.html }}></div>
            </Link>
          </Fragment>
        );
      })}
    </div>
  );
};
