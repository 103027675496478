/* eslint-disable no-undef */
import axios from '../../config/axiosInterceptor';

export const fetchParticipants = async (
  searchEmail,
  currentPage,
  participantsPerPage,
  companyFilter,
  setParticipantsData
) => {
  try {
    const res = await axios.get(
      `/admin/participants?${
        searchEmail !== '' ? `search=${searchEmail}` : ''
      }&page=${currentPage}&limit=${participantsPerPage}${
        companyFilter !== '' ? `&filterByCompany=${companyFilter}` : ''
      }`
    );
    setParticipantsData(res.data);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
  }
};

export const fetchCompanies = async (setCompanies) => {
  try {
    const res = await axios.get('/admin/companies');
    setCompanies(res.data.companies);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
  }
};

export const addParticipant = async (
  setLoading,
  setSuccess,
  toggleModals,
  setNewParticipant,
  setApiError,
  newParticipant
) => {
  setLoading(true);
  try {
    await axios.post('/admin/participants', {
      email: newParticipant
    });
    setSuccess('Participante añadido correctamente');
    setLoading(false);
    toggleModals();
    setNewParticipant('');
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    setApiError(error.response.data.error || error.message);
    setLoading(false);
  }
};

export const deleteParticipant = async (
  setLoading,
  participantEmailToDelete,
  setSuccess,
  toggleDeleteModal,
  setApiError
) => {
  setLoading(true);
  try {
    await axios.delete(`/admin/participants/${participantEmailToDelete}`);
    setSuccess('Participante eliminado correctamente');
    toggleDeleteModal();
    setLoading(false);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    setApiError(error.response.data.message || error.message);
    setLoading(false);
  }
};

export const sendReminder = async (email, setLoading, setSuccess, setApiError) => {
  setLoading(true);
  try {
    await axios.post(`/admin/participants/reminder`, {
      email
    });
    setSuccess('Invitación reenviada correctamente');
    setLoading(false);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    setApiError(error.response.data.message || error.message);
    setLoading(false);
  }
};

export const sendCompanyReminder = async (setSuccess, setApiError, setLoading) => {
  setLoading(true);
  try {
    const response = await axios.post(`/admin/participants/companyReminder`);
    setSuccess(response.data.message);
    setLoading(false);
  } catch (error) {
    setApiError(error.response.data.message || error.message);
    setLoading(false);
  }
};

export const getCompanyStats = async (setStats, setParticipantsQuantityError) => {
  try {
    const res = await axios.get('/admin/stats');
    // In case there's not enough data to show the stats because the company has less than 5 participants
    if (res.status === 206) {
      setParticipantsQuantityError(true);
    } else {
      setStats(res.data);
    }
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
  }
};

export const getAdminStats = async (setStats, userCompany, setApiError) => {
  try {
    const res = await axios.get(`/admin/stats/superAdminStats/${userCompany}`);
    setStats(res.data);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    setApiError(error.response.data.message || error.message);
  }
};
