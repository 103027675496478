/* eslint-disable no-undef */
import React from 'react';

// eslint-disable-next-line react/prop-types
const Answer = ({ answered }) => {
  return (
    <p className="flex items-center">
      {answered ? 'Respondido' : 'No respondido'}
      {answered ? (
        <span>
          <svg
            width="18"
            height="18"
            className="ml-3"
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="9" cy="9" r="9" fill="#05BD01" />
            <path
              d="m5.455 9 2.598 2.598a.5.5 0 0 0 .749-.048L13.09 6"
              stroke="#fff"
              strokeWidth="2"
              strokeLinecap="round"
            />
          </svg>
        </span>
      ) : (
        <span>
          <span className="ml-3 rounded-full h-5 text-center flex items-center justify-center w-5 text-white bg-red-600">
            X
          </span>
        </span>
      )}
    </p>
  );
};

export default Answer;
