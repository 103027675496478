import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from '../../components';
import ChangePassword from './ChangePassword';

const MyAccount = () => {
  const { userName, userEmail, userCompanyName } = useSelector((state) => state.user);

  const [isOpen, setIsOpen] = useState(false);

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="flex flex-col mt-5 md:mt-10 px-8">
        <p className="text-xl">Mi cuenta</p>

        <div className="">
          <p className="text-secondary my-3 text-sm">{userCompanyName || 'HumaninLab'}</p>
          <p className="text-sm">{userName}</p>
          <p className="text-sm">{userEmail}</p>
        </div>

        <Button onClick={toggleModal}>Cambiar contraseña</Button>
      </div>

      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <ChangePassword />
      </Modal>
    </>
  );
};

export default MyAccount;
