import { Link } from 'react-router-dom';

import Logo from '../../assets/img/logo_white.svg';
import { StatsNavbar } from '../../containers/stats/CompanyStatsSections/StatsNavbar';

export const NAV_BAR_HEIGHT = 152;
export const NAV_BAR_HEIGHT_MOBILE = 50;

export const NavBar = () => {
  return (
    <nav className="absolute left-0 right-0 top-0 z-10 bg-primary px-3 shadow">
      <div className="mx-auto flex px-3 py-2 md:py-5">
        <Link className="mx-auto md:mx-0" href="/">
          <img src={Logo} className="h-9 md:h-28" />
        </Link>
      </div>

      <StatsNavbar />
    </nav>
  );
};
