import React from 'react';
import cx from 'classnames';
import errorIcon from '../assets/icons/error.svg';
import successIcon from '../assets/icons/success.svg';

// eslint-disable-next-line react/prop-types
const Notification = ({ className, message, type }) => {
  return (
    <div
      className={cx([
        'border-2',
        {
          'bg-red-50 border-red-400 text-red-600': type === 'error',
          'bg-green-50 border-green-400 text-green-600': type === 'success'
        },
        'text-md p-3 rounded-lg flex justify-between',
        className
      ])}>
      <div>
        <div className="flex items-center">
          {type === 'error' && <img className="mr-4" src={errorIcon} alt={type} />}
          {type === 'success' && <img className="mr-4" src={successIcon} alt={type} />}
          <p>{message}</p>
        </div>
      </div>
    </div>
  );
};

export default Notification;
