/* eslint react/prop-types: 0 */
const customTooltip = ({ valueType = 'number' } = {}) => {
  // eslint-disable-next-line react/display-name
  return ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = Number(payload[0].value).toFixed(2);
      const transformedValue = `${value}${valueType === 'perc' ? '%' : ''}`;
      return (
        <div className="custom-tooltip py-3 px-5 bg-white border-2 border-gray-500">
          <p className="label">{`${label}: ${transformedValue}`}</p>
        </div>
      );
    }

    return null;
  };
};

export const CustomTooltip = {
  PERCENTAGE: customTooltip({ valueType: 'perc' }),
  NUMBER: customTooltip({ valueType: 'number' })
};
