import axios from '../../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { Input, Modal, Notification, Pagination } from '../../../components';
import { Link } from 'react-router-dom';
import plusIcon from '../../../assets/icons/plus.svg';
import AddCompany from './AddCompany';

import noDataIlllusration from '../../../assets/icons/noData.svg';

const Companies = () => {
  const [companySearch, setCompanySearch] = useState('');
  const [companiesPerPage, setCompaniesPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [companiesData, setCompaniesData] = useState({
    companies: [],
    count: 0,
    currentPage: '0',
    totalPages: 0
  });
  const [isOpen, setIsOpen] = useState(false);
  const [successCreatingCompany, setSuccessCreatingCompany] = useState(null);

  const lastCompanyIndex = currentPage * companiesPerPage;
  const firstCompanyIndex = lastCompanyIndex - companiesPerPage;
  const currentCompanies = companiesData.companies.slice(firstCompanyIndex, lastCompanyIndex);

  const nextPage = () => setCurrentPage(currentPage + 1);
  const prevPage = () => setCurrentPage(currentPage - 1);

  const fetchCompanies = async () => {
    try {
      const res = await axios.get(
        `/admin/companies?search=${companySearch}&limit=${companiesPerPage}&page=${companiesData.currentPage}`
      );
      setCompaniesData(res.data);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    }
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    fetchCompanies();

    if (successCreatingCompany) {
      const timer = setTimeout(() => {
        setSuccessCreatingCompany(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [isOpen, successCreatingCompany, companySearch]);

  return (
    <>
      <div className="flex w-full flex-col mt-10 px-8">
        <div className="flex flex-col mb-7 sm:flex-row w-full justify-between">
          <p className="text-xl">Empresas</p>
          <button
            onClick={toggleModal}
            className="px-6 py-3 rounded-md bg-green_btn w-56 items-center text-white flex">
            <img src={plusIcon} alt="add" draggable={false} className="mr-2" />
            <span>Agregar empresa</span>
          </button>
        </div>

        <div className="w-full">
          {' '}
          <Input
            showCrossIcon
            type="text"
            value={companySearch}
            onIconClick={() => setCompanySearch('')}
            onChange={(e) => setCompanySearch(e.target.value)}
            placeholder="Buscar por empresa..."
          />
          {successCreatingCompany !== null && (
            <Notification type="success" message="La empresa fue agregada exitosamente" />
          )}
          {companiesData.companies.length > 0 ? (
            <>
              <div className="rounded-lg  border ">
                <table className="table-auto w-full sm:border-separate sm:border-tertiary sm:rounded-lg">
                  <tbody>
                    {companiesData.companies.length > 0
                      ? currentCompanies.map((company, index) => (
                          <tr key={company._id} className="flex flex-col sm:flex-row">
                            <td
                              className={`sm:border-r sm:w-56 border-tertiary  bg-light_gray sm:bg-white md:rounded-l-lg p-6 text-sm ${
                                index === 0 ? 'rounded-t-lg sm:rounded-none' : ''
                              }`}>
                              {company.name} <br />
                              <span className="text-primary">
                                {company.participants}/{company.maxParticipants} participantes
                              </span>
                            </td>
                            <td
                              className={`p-6 bg-white text-sm ${
                                index === currentCompanies.length - 1 ? 'rounded-b-lg' : ''
                              }`}>
                              <Link
                                to={`/companies/${company._id}/admin-users`}
                                className="text-secondary flex hover:underline">
                                Usuarios administradores
                                <p className="ml-3 rounded-full h-6 text-center flex items-center justify-center w-6 text-white bg-red-500">
                                  <span>{company.adminUsers}</span>
                                </p>
                              </Link>
                              <Link
                                to={`/companies/${company._id}/edit`}
                                className="text-secondary flex hover:underline"
                                onClick={toggleModal}>
                                Editar empresa/plan
                              </Link>
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
              </div>

              <Pagination
                itemsPerPage={companiesPerPage}
                totalResults={companiesData.companies.length}
                nextPage={nextPage}
                prevPage={prevPage}
                currentPage={currentPage}
                setResultsPerPage={setCompaniesPerPage}
              />
            </>
          ) : (
            <div className="flex my-4 flex-col items-center justify-center">
              <img className="h-24 w-24" src={noDataIlllusration} alt="no data" draggable={false} />
              <p className="text-2xl text-center">No se encontraron empresas</p>
            </div>
          )}
        </div>
      </div>
      <Modal isOpen={isOpen} toggleModal={toggleModal}>
        <AddCompany
          toggleModal={toggleModal}
          setSuccessCreatingCompany={setSuccessCreatingCompany}
        />
      </Modal>
    </>
  );
};

export default Companies;
