import React, { useCallback } from 'react';
import WordCloud from 'react-d3-cloud';
import PropTypes from 'prop-types';
import { isEmpty, chain, sample, upperCase, last } from 'lodash';

const COLORS = [
  '#3D4C5C',
  '#80A4AF',
  '#E53D3D',
  '#B8CCD3',
  '#0F70B7',
  '#78C6FF',
  '#288E8E',
  '#6CC0C0',
  '#ACDFDF',
  '#8C1313',
  '#FE8163',
  '#DEA99C',
  '#FFD9D0',
  '#E1DAD9',
  '#DDB17B',
  '#A07F56'
];

const Wordcloud = ({ words }) => {
  if (isEmpty(words)) return;
  const sortedWords = chain(words)
    .uniqBy('text')
    .orderBy('value', 'asc')
    .map((w) => ({ ...w, text: upperCase(w.text) }))
    .value();
  const maxWordValue = last(sortedWords).value;

  const fontSize = useCallback((word) => {
    const size = 50 * (word.value / maxWordValue);
    return size < 6 ? 6 : size;
  }, []);
  const fill = useCallback(() => sample(COLORS), []);
  const random = useCallback(() => 0.5, []);

  return (
    <div className="my-6 w-full text-center -mt-4">
      <WordCloud
        data={sortedWords}
        font="Urbanist"
        fontSize={fontSize}
        rotate={0}
        padding={4}
        random={random}
        fill={fill}
      />
    </div>
  );
};

export default Wordcloud;

Wordcloud.propTypes = {
  words: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      value: PropTypes.number.isRequired
    })
  )
};
