import React from 'react';
import PropTypes from 'prop-types';
import { Table } from '../../components';

const AddMultipleParticipants = ({ newParticipants, setSuccess }) => {
  const cols = ['Email', 'Status'];

  const participantsData = newParticipants.map((participant) => {
    return {
      email: participant
    };
  });

  return (
    <div>
      <Table cols={cols} data={participantsData} setSuccess={setSuccess} />
    </div>
  );
};

export default AddMultipleParticipants;

AddMultipleParticipants.propTypes = {
  newParticipants: PropTypes.arrayOf(String) || PropTypes.string,
  toggleAddMultipleParticipantsModal: PropTypes.func.isRequired,
  toggleAddParticipantsModal: PropTypes.func.isRequired,
  setSuccess: PropTypes.func
};
