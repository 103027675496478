/* eslint-disable no-undef */
import axios from '../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { Input, Button, Notification } from '../../components';
import { validatePasswordLength, passwordsAreEqual } from '../../utils/validators';

const CreatePasswordForm = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [changePassword, setChangePassword] = useState({
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    password: '',
    confirmPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);

  const queryToken = searchParams.get('createPasswordToken');

  const passwordIsValid = () => {
    return (
      !errors.confirmPassword &&
      !errors.password &&
      changePassword.confirmPassword.trim() !== ' ||' &&
      changePassword.password.trim() !== '' &&
      !loading &&
      passwordsAreEqual(changePassword.password, changePassword.confirmPassword)
    );
  };

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/admin/adminUsers/createPasswordFromEmail', {
        emailToken: queryToken,
        password: changePassword.confirmPassword
      });
      if (res.status === 200) {
        setSuccess(res.data.message);
        searchParams.delete('createPasswordToken');
        setSearchParams(searchParams);
      }
      setLoading(false);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setApiError(error.response.data.error || error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => {
        navigate('/login');
      }, 2000);
      return () => clearTimeout(timer);
    }

    if (!queryToken) {
      navigate('/login');
    }
  }, [apiError, success, queryToken]);

  return (
    <div className="h-screen w-screen flex flex-col justify-between">
      <header>
        <img src={logo} draggable={false} className="w-56 block mb-12 mt-4 mx-auto " alt="logo" />
        <p className="w-5/6 sm:w-2/6 mx-auto text-center">
          ¡Hola! Has recibido una invitación de Humanin Lab. Crea ahora tu contraseña para ingresar
          a la plataforma.
        </p>
      </header>
      <main className="my-4 gap-y-6 w-auto mx-4 sm:w-1/3 sm:mx-auto">
        <Input
          type="password"
          label="Contraseña / Password *"
          name="password"
          onChange={(e) => {
            setChangePassword({ ...changePassword, password: e.target.value });
            !validatePasswordLength(e.target.value)
              ? setErrors({
                  ...errors,
                  password: 'La contraseña debe tener al menos 10 caracteres'
                })
              : setErrors({ ...errors, password: '' });
          }}
          errors={errors?.password}
        />
        <Input
          type="password"
          label="Repite Contraseña / Password *"
          name="password"
          onChange={(e) => {
            setChangePassword({ ...changePassword, confirmPassword: e.target.value });
            !validatePasswordLength(e.target.value)
              ? setErrors({
                  ...errors,
                  confirmPassword: 'La contraseña debe tener al menos 10 caracteres'
                })
              : setErrors({ ...errors, confirmPassword: '' });
          }}
          errors={errors?.confirmPassword}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && passwordIsValid()) {
              handleChangePassword();
            }
          }}
        />
        {apiError ? (
          <Notification type="error" message={apiError} />
        ) : success ? (
          <Notification type="success" message={success} />
        ) : null}

        {!passwordsAreEqual(changePassword.password, changePassword.confirmPassword) && (
          <p className="text-red-600 text-center">Las contraseñas deben coincidir</p>
        )}
        <Button
          disabled={!passwordIsValid()}
          onClick={handleChangePassword}
          mx="mx-auto"
          loading={loading}>
          Crear contraseña
        </Button>
      </main>
      <footer className="mb-4">
        <p className="text-center">¿Tienes una consulta?</p>
        <a
          className="text-secondary block text-center hover:underline"
          href="mailto:clientes@humaninlab.com">
          clientes@humaninlab.com
        </a>
      </footer>
    </div>
  );
};

export default CreatePasswordForm;
