/* eslint-disable no-undef */
import axios from '../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Input, Button, Notification } from '../../components';
import { logout } from '../../utils/logout';
import { validatePasswordLength, passwordsAreEqual } from '../../utils/validators';

const ChangePassword = () => {
  const navigate = useNavigate();

  const [changePassword, setChangePassword] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [errors, setErrors] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: ''
  });
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChangePassword = async () => {
    setLoading(true);
    try {
      const res = await axios.post('/auth/changePassword', {
        oldPassword: changePassword.currentPassword,
        newPassword: changePassword.confirmNewPassword
      });
      if (res.status === 200) {
        setSuccess(res.data.message);
      }
      setLoading(false);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setApiError(error.response.data.error || error.message);
      setLoading(false);
    }
  };

  const passwordIsValid = () => {
    return (
      !errors.currentPassword &&
      !errors.confirmNewPassword &&
      !errors.newPassword &&
      changePassword.confirmNewPassword.trim() !== ' ||' &&
      changePassword.newPassword.trim() !== '' &&
      changePassword.currentPassword.trim() !== '' &&
      !loading &&
      passwordsAreEqual(changePassword.newPassword, changePassword.confirmNewPassword)
    );
  };

  useEffect(() => {
    if (success) {
      const timer = setTimeout(async () => {
        await logout(localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY));
        navigate('/login');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success, changePassword, setChangePassword]);

  return (
    <div className="  flex flex-col sm:px-8 ">
      <header>
        <p className="text-secondary text-center sm:text-left text-2xl">Cambiar contraseña</p>
      </header>
      <main className="my-4 mt-12 gap-y-6  mx-4 sm:w-2/3 ">
        <Input
          type="password"
          label="Tu contraseña actual *"
          name="password"
          value={changePassword.currentPassword}
          onFocus={() => setApiError(null)}
          onChange={(e) => {
            setChangePassword({ ...changePassword, currentPassword: e.target.value });
            !validatePasswordLength(e.target.value)
              ? setErrors({
                  ...errors,
                  currentPassword: 'La contraseña debe tener al menos 10 caracteres'
                })
              : setErrors({ ...errors, currentPassword: '' });
          }}
          errors={errors?.currentPassword}
        />
        <Input
          type="password"
          label="Nueva contraseña *"
          name="password"
          value={changePassword.newPassword}
          onChange={(e) => {
            setChangePassword({ ...changePassword, newPassword: e.target.value });
            !validatePasswordLength(e.target.value)
              ? setErrors({
                  ...errors,
                  newPassword: 'La contraseña debe tener al menos 10 caracteres'
                })
              : setErrors({ ...errors, newPassword: '' });
          }}
          errors={errors?.newPassword}
        />
        <Input
          type="password"
          label="Repite la nueva contraseña *"
          name="password"
          value={changePassword.confirmNewPassword}
          onChange={(e) => {
            setChangePassword({ ...changePassword, confirmNewPassword: e.target.value });
            !validatePasswordLength(e.target.value)
              ? setErrors({
                  ...errors,
                  confirmNewPassword: 'La contraseña debe tener al menos 10 caracteres'
                })
              : setErrors({ ...errors, confirmNewPassword: '' });
          }}
          errors={errors?.confirmNewPassword}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && passwordIsValid()) {
              handleChangePassword();
            }
          }}
        />
        {apiError ? (
          <Notification type="error" message={apiError} />
        ) : success ? (
          <Notification type="success" message={success} />
        ) : null}

        {!passwordsAreEqual(changePassword.newPassword, changePassword.confirmNewPassword) && (
          <p className="text-red-600 text-center">Las contraseñas deben coincidir</p>
        )}
        <Button
          disabled={!passwordIsValid()}
          mx="mx-auto sm:mx-0"
          onClick={handleChangePassword}
          loading={loading}>
          Guardar contraseña
        </Button>
      </main>
    </div>
  );
};

export default ChangePassword;
