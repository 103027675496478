import React, { useState } from 'react';

import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
const Input = ({
  label,
  type,
  onChange,
  errors,
  placeholder,
  name,
  showValidIcon,
  showCrossIcon,
  onIconClick,
  onKeyDown,
  disabled,
  value,
  onFocus
}) => {
  const [passwordVisibility, setPasswordVisibility] = useState(false);

  const toggleVisibility = () => {
    setPasswordVisibility(!passwordVisibility);
  };

  return (
    <div className="mb-7">
      {label ? (
        <label htmlFor={type} className="block text-md font-medium text-gray-700 mb-2 ">
          {label}
        </label>
      ) : null}

      <div className="relative">
        <input
          type={passwordVisibility ? 'text' : type}
          className={`w-full rounded-lg ${errors ? 'border-red-600' : 'border-gray-200'} ${
            disabled && 'opacity-50'
          }  p-4 pr-12 text-sm shadow-sm`}
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          onKeyDown={onKeyDown}
          disabled={disabled}
          onFocus={onFocus}
        />

        <span className="absolute inset-y-0 right-4 inline-flex items-center">
          {type === 'password' ? (
            !passwordVisibility ? (
              <svg
                onClick={toggleVisibility}
                className="cursor-pointer"
                width="20"
                height="13"
                viewBox="0 0 20 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M19.126 6.447c.01.016.012.032.012.045a.09.09 0 0 1-.013.045l.647.378-.647-.378C18.308 7.936 15.342 12.25 10 12.25c-5.342 0-8.308-4.314-9.125-5.713a.091.091 0 0 1-.013-.045c0-.013.003-.029.012-.045L.23 6.085l.645.362C1.654 5.054 4.52.75 10 .75s8.346 4.304 9.126 5.697Z"
                  stroke="#000"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.25 6.35a2.35 2.35 0 1 1-4.7 0 2.35 2.35 0 0 1 4.7 0Z"
                  stroke="#000"
                  strokeWidth="1.5"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                onClick={toggleVisibility}
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="h-5 w-5 cursor-pointer font-bold">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12c1.292 4.338 5.31 7.5 10.066 7.5.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                />
              </svg>
            )
          ) : type === 'email' && showValidIcon ? (
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <circle cx="9" cy="9" r="9" fill="#05BD01" />
              <path
                d="m5.455 9 2.598 2.598a.5.5 0 0 0 .749-.048L13.09 6"
                stroke="#fff"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          ) : type === 'text' && showCrossIcon ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              onClick={onIconClick}
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 cursor-pointer">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          ) : null}
        </span>
      </div>
      {errors && <p className="text-red-600 text-sm mt-2">{errors}</p>}
    </div>
  );
};

export default Input;

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  errors: PropTypes.string,
  placeholder: PropTypes.string,
  name: PropTypes.string,
  showValidIcon: PropTypes.bool,
  showCrossIcon: PropTypes.bool,
  onIconClick: PropTypes.func,
  onKeyDown: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.string,
  onFocus: PropTypes.func
};
