import logo from '../../assets/img/logo.png';
const NotFoundView = () => {
  return (
    <div className="h-screen overflow-y-hidden">
      <div className="mb-6">
        <img src={logo} alt="logo" className="w-64 mx-auto mt-6" />
      </div>
      <div className="flex flex-col justify-center h-2/3 ">
        <h1 className="text-9xl text-center">404</h1>
        <p className="text-primary text-center sm:text-2xl">
          La pagina que estas buscando no existe
        </p>
      </div>
    </div>
  );
};

export default NotFoundView;
