import cx from 'classnames';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import Spinner from '../Spinner';
import { NAV_BAR_HEIGHT, NAV_BAR_HEIGHT_MOBILE, NavBar } from '../Navbar/Navbar';
import Sidebar from '../Sidebar';
import { useScreen } from '../../hooks/useScreen';

export const Page = ({
  isLoading = false,
  hasInnerPadding = true,
  backgroundColorClass = 'bg-white'
}) => {
  const { isLaptop } = useScreen();
  const isLoadingInner = isLoading;

  const navbarHeight = isLaptop ? NAV_BAR_HEIGHT : NAV_BAR_HEIGHT_MOBILE;

  return (
    <div className="flex flex-col text-primary">
      <NavBar />

      <div
        className="absolute flex flex-col md:flex-row h-screen w-screen"
        style={{ height: `calc(100vh - ${navbarHeight}px)`, top: navbarHeight }}>
        <Sidebar />
        <div
          id="main-container"
          className={cx('relative flex-1', backgroundColorClass, {
            ['overflow-x-auto']: !isLoadingInner,
            ['px-6 pb-12']: hasInnerPadding
          })}>
          <Outlet />
          {isLoadingInner && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-400 opacity-30">
              <Spinner />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

Page.propTypes = {
  children: PropTypes.element,
  isLoading: PropTypes.bool,
  hasInnerPadding: PropTypes.bool,
  backgroundColorClass: PropTypes.string
};
