import PropTypes from 'prop-types';
import { get, uniq } from 'lodash';
import { useCallback } from 'react';
import cx from 'classnames';
import {
  Radar,
  RadarChart,
  PolarGrid,
  Legend,
  PolarAngleAxis,
  PolarRadiusAxis,
  ResponsiveContainer
} from 'recharts';
import Wordcloud from '../../../components/charts/WordCloud';
import { COLORS } from './Composition';

const archetypesCards = [
  {
    title: 'FUERZA',
    text: 'Cultura poco aprendiente, de baja escucha e incorporación de lo nuevo y diferente, sean innovaciones o habilidades personales'
  },
  {
    title: 'TECH',
    text: 'Refuerza al grupo Apertura aunque es menos rígida porque cree en la flexibilidad y en la adaptación. Este tipo de cultura va generando paso a paso, creen en el life balance y se toman el tiempo para crear. Todos se sienten pares, las jerarquías no se notan demasiado. Aman la disrupción'
  },
  {
    title: 'APERTURA',
    text: 'Es una cultura que aprende pero les cuesta mirar el pasado. Están centrados en la juventud, son libres y con poder sobre sí mismos. Les cuesta rendir cuentas. Pueden no valorar la experiencia.'
  },
  {
    title: 'PIEDRA',
    text: 'Cultura de otro tiempo en la que el management tiene una mirada puesta en el corto plazo, donde las jerarquías se valoran y mantienen el status quo que da lugar al micromanagement. Una estructura pesada de mover en la que cualquier cambio es difícil de implementar y que no empodera a los colaboradores. Verticalista'
  },
  {
    title: 'NFT',
    text: 'En este tipo de cultura la apertura a la diversidad es total, se construye a través de la diversidad. Puede ser demasiado democrática y tornarse burocrática y poco ágil. Es la cultura del impacto social.'
  }
];

export const WordPlayResults = ({ stats }) => {
  const _companyArchetypes = get(stats, 'companyArchetypes');
  const companyArchetypes = _companyArchetypes.labels.map((label, i) => {
    const data = {
      name: label,
      fullMark: 100
    };
    _companyArchetypes.newDatasets.forEach((dataset) => {
      data[dataset.label] = dataset.data[i];
    });

    return data;
  });

  const driversBySector = stats.driversBySector;
  const drivers = get(stats, 'driversBySector.drivers', []);

  const importantWord = useCallback(
    (word) =>
      uniq([driversBySector.mostRepeatedWord, ...driversBySector.outstandingFeature]).includes(
        word
      ),
    [driversBySector]
  );

  return (
    <>
      <section id="WordPlayResults" className="mt-16 md:mt-10 md:py-10">
        <p className="uppercase text-primary font-bold text-3xl">RESULTADOS JUEGO DE PALABRAS</p>{' '}
      </section>

      <section className="md:mt-10">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Arquetipos Culturales
        </p>

        <p className="font-semibold">
          El juego de palabras mide la percepción que tienen las personas sobre cómo es, en el día a
          día, la cultura de la organización.
        </p>
        <br />
        <p>
          Cada palabra del juego se relaciona a un determinado arquetipo cultural y, además, cada
          palabra tiene una ponderación respecto a si facilita o no la Diversidad, Equidad e
          Inclusión. Del puntaje ponderado surge el resultado del juego de palabras que combinado al
          de respuesta automática proporciona el índice de Permeabilidad de la Cultura de la
          Organización a DEI. Los arquetipos culturales sirven como información relevante para saber
          cuáles sobresalen como cultura predominante.
        </p>

        <div className="mt-10 flex items-stretch flex-wrap justify-center -mx-4">
          {archetypesCards.map((card, index) => (
            <div key={index} className="md:w-1/3 p-4 h-auto">
              <div className="py-5 px-8 bg-gray-100 border-gray-500 rounded-md border-2 h-full">
                <h2 className="text-xl font-semibold text-primary p-3">{card.title}</h2>
                <p className="text-primary p-3">{card.text}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

      <section className="mt-5 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Glosario de Palabras
        </p>

        <p className="font-semibold text-center text-xl">
          ¿Cuál es el significado de las palabras?
        </p>

        <p className="font-bold text-xl my-10">Arquetipo Fuerza</p>

        <div className="grid lg:grid-cols-2 md:gap-24">
          <div>
            <p className="font-bold">Competitivos:</p>
            <p>
              Se miden con una vara alta, no les gusta perder y son conscientes que cualquier
              negocio tiene que ser competitivo, es decir estar a la altura de lo que el mercado
              requiere.
            </p>
            <br />
            <p className="font-bold">Expeditivos:</p>
            <p>Hacer rápido, no enredarse, tomar decisiones y hacer, aunque se equivoquen.</p>
            <br />
            <p className="font-bold">Ansiosos:</p>
            <p>
              Lo queremos YA. Les cuesta seguir un proceso, quieren resultados rápidos, no respetan
              los tiempos de los demás.
            </p>
          </div>

          <div>
            <p className="font-bold">Cerrados:</p>
            <p>
              Están centrados en sí mismos, son un equipo o una organización endogámica que tiene
              sus propios códigos y les cuesta aceptar al diferente o externo al propio círculo.
            </p>
            <br />
            <p className="font-bold">Exitistas:</p>
            <p>
              Creen en el éxito basado en resultados y les importa mucho tener ese tipo de éxito.
              Van por la copa sin detenerse demasiado en el proceso.
            </p>
            <br />
            <p className="font-bold">Soberbios:</p>
            <p>
              “Sabemos cómo hacer las cosas” No están abiertos a aprender del error, están parados
              en el éxito y se apoyan en frases como: “así nos va bien”.
            </p>
          </div>
        </div>

        <hr className="my-10 h-1 bg-secondary border-0" />

        <p className="font-bold text-xl my-10">Arquetipo Tech</p>

        <div className="grid lg:grid-cols-2 md:gap-24">
          <div>
            <p className="font-bold">Disruptiva:</p>
            <p>Van por lo diferente para romper estructuras</p>
            <br />
            <p className="font-bold">Flexible:</p>
            <p>
              Cada uno es responsable por sí mismo. La responsabilidad es una característica que
              subyace a la flexibilidad. La cultura flexible es adaptativa al mercado, a las
              demandas de las personas, etc.
            </p>
            <p className="font-bold">Aprendizaje:</p>
            <p>
              Un valor esencial de la cultura. Aprenden de todo lo que hacen, aún del éxito. Es una
              práctica instalada.
            </p>
            <br />
            <p className="font-bold">Relajada:</p>
            <p>
              Las personas se sienten como en casa, no se muestran, ni se sienten tensiones. La
              autenticidad es un valor de la cultura relajada.
            </p>
          </div>

          <div>
            <p className="font-bold">Jóvenes:</p>
            <p>Personas menores de 35 años como valor organizacional.</p>
            <br />
            <p className="font-bold">Circular:</p>
            <p>
              No reconoce jerarquías, las decisiones se toman por consenso, todos los miembros se
              ven como iguales
            </p>
            <br />
            <p className="font-bold">Ágil:</p>
            <p>
              En una cultura ágil no hay burocracia, las decisiones son rápidas y los procesos están
              diseñados en función de facilitar el trabajo de las personas y no al revés.
            </p>
            <br />
            <p className="font-bold">Masculina:</p>
            <p>
              Valora lo que se conoce tradicionalmente como “masculino”. Atributos con los que en el
              pasado se definía a los hombres como: fuerza, empuje, garra, éxito, ambición.
            </p>
          </div>
        </div>

        <hr className="my-10 h-1 bg-secondary border-0" />

        <p className="font-bold text-xl my-10">Arquetipo Apertura</p>

        <div className="grid lg:grid-cols-2 md:gap-24">
          <div>
            <p className="font-bold">Extrovertidos</p>
            <p>
              Se utiliza como característica aceptada dentro de la compañía refiriéndose al
              auto-marketing, a exponer bien, a hablar, dar opiniones y hacerse un lugar con marca
              personal
            </p>
            <br />
            <p className="font-bold">Inconformistas</p>
            <p>
              Siempre van por más y muchas veces no pueden disfrutar de haber obtenido un logro,
              Nada es suficiente.
            </p>
            <br />
            <p className="font-bold">Ambiciosos</p>
            <p>Quieren llegar lejos, ser mejores en todo. Más y mejores resultados</p>
          </div>

          <div>
            <p className="font-bold">Empoderados</p>
            <p>
              Hacen por sí mismos. No están pendientes de si su jefe avala o no sus acciones. Toman
              responsabilidad para avanzar.
            </p>
            <br />
            <p className="font-bold">Creativos</p>
            <p>
              Siempre encuentran una opción para resolver un problema. La creatividad se respira en
              la organización.
            </p>
            <br />
            <p className="font-bold">Innovadores</p>
            <p>Siempre abiertos a lo nuevo y a implementarlo.</p>
            <br />
            <p className="font-bold">Inclusivos</p>
            <p>
              Tienen en cuenta, respetan y escuchan todas las voces. No importa de quien venga,
              están abiertos y valoran las diferencias.
            </p>
          </div>
        </div>

        <hr className="my-10 h-1 bg-secondary border-0" />

        <p className="font-bold text-xl my-10">Arquetipo Piedra</p>

        <div className="grid lg:grid-cols-2 md:gap-24">
          <div>
            <p className="font-bold">Cortoplacista</p>
            <p>
              Gestiona para el corto plazo, casi en el día a día, no involucra a las personas en una
              visión más amplia.
            </p>
            <br />
            <p className="font-bold">Jerarquizada</p>
            <p>
              Las jerarquías son importantes, es una cultura verticalista que requiere de la
              decisión final de la autoridad para avanzar
            </p>
            <br />
            <p className="font-bold">Statu Quo</p>
            <p>
              Estructuras fijas, donde todo lo que define a la cultura de la organización como
              símbolos, rasgos, creencias sobre lo que “debe ser” son intocables. El statu quo tiene
              enormes anclas en el pasado y en las tradiciones.
            </p>
          </div>

          <div>
            <p className="font-bold">Burocrática</p>
            <p>
              Las personas están al servicio de los procesos, tanto operativos como de toma de
              decisión, y finalmente éstos se convierten en la estrella y no al revés.
            </p>
            <br />
            <p className="font-bold">Micromanagement</p>
            <p>
              Los líderes miran sólo su quinta, su espacio. Bajan decisiones uno a uno, sin dejar
              ver el bosque, solo el árbol. No enamoran a sus equipos con una visión amplia, solo
              ponen la mirada en lo pequeño,
            </p>
          </div>
        </div>

        <hr className="my-10 h-1 bg-secondary border-0" />

        <p className="font-bold text-xl my-10">Arquetipo NFT</p>

        <div className="grid lg:grid-cols-2 md:gap-24">
          <div>
            <p className="font-bold">Curiosa</p>
            <p>
              La pregunta y la avidez por saber no tiene límite. La curiosidad está en todos y para
              todos. Se promueve como un valor profundo de la organización.
            </p>
            <br />
            <p className="font-bold">Colaborativa</p>
            <p>
              Siempre dispuestos a ayudar a los demás, no importa de qué área sean. En una cultura
              colaborativa prevalece la efectividad colectiva.
            </p>
            <br />
            <p className="font-bold">Empática</p>
            <p>
              {' '}
              No sólo son empáticos los miembros de la organización entre sí, sino con lo que sucede
              en las comunidades cercanas a la organización.
            </p>
            <br />
            <p className="font-bold">Visionaria</p>
            <p>
              Mira al futuro, genera una visión con la que entusiasma al resto. Integra a todas
              personas para alinearlas a la visión de cía. Una cultura visionaria también es la que
              se adelanta al futuro y crea un mundo distinto.
            </p>
            <br />
            <p className="font-bold">People Centric</p>
            <p>Cultura centrada en las personas, en su bienestar integral.</p>
          </div>

          <div>
            <p className="font-bold">Vanguardista</p>
            <p>Son pioneros, trabajan para el futuro</p>
            <br />
            <p className="font-bold">Democrática</p>
            <p>
              Todos son parte de las decisiones, pero corre el riesgo de caer en la burocracia y de
              no integrar a las minorías
            </p>
            <br />
            <p className="font-bold">Cambiante</p>
            <p>
              Todo puede cambiar de un momento a otro. No hay demasiadas certezas del rumbo y se
              trabaja con prioridades más que desde una visión.
            </p>
            <br />
            <p className="font-bold">Revolucionaria</p>
            <p>Es una cultura que valora lo diferente y que rompe permanentemente el statu quo.</p>
            <br />
            <p className="font-bold">Comunidad</p>
            <p>
              Construye el sentido de pertenencia a la comunidad de personas, más que a un nombre.
              Son conscientes que la comunidad ayudará a crecer a las personas porque se sienten
              apoyados y confiados en ella.
            </p>
          </div>
        </div>
      </section>

      <section className="mt-5 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Nube de Palabras
        </p>

        <div className="grid lg:grid-cols-2 gap-x-10">
          <div className="text-center">
            <p className="text-lg">Cantidad de Palabras elegidas por todos los respondientes</p>
            <Wordcloud words={stats.wordsMap} />
          </div>
          <div className="text-center">
            <p className="text-lg">Drivers para trabajar la permeabilidad de la cultura a DEI</p>
            <Wordcloud words={stats.wordsByPointsMap} />
          </div>
        </div>
      </section>

      <section className="mt-5 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Drivers por áreas
        </p>

        <div className="relative md:mt-24 text-sm md:text-xl">
          <div className="w-1 bg-primary absolute top-0 bottom-0 m-auto left-0 right-0"></div>
          {drivers.map((driver) => (
            <div className="flex gap-x-3 md:gap-x-24 mt-10" key={driver.name}>
              <div className="flex-1 text-right">
                <p className="break-all">{driver.name}</p>
              </div>
              <div className="flex-1 text-left">
                {driver.values.map((word) => (
                  <p
                    key={word}
                    className={cx([
                      'uppercase font-medium break-all',
                      {
                        'text-red-500': importantWord(word)
                      }
                    ])}>
                    {word}
                  </p>
                ))}
              </div>
            </div>
          ))}
        </div>

        <div
          lang="es"
          className="hyphens-auto text-md text-center md:text-left mt-20 md:w-1/2 grid lg:grid-cols-2 m-auto font-semibold border-2 border-neutral-500 bg-zinc-50 py-5 px-2 md:px-10 md:text-xl">
          <p className="uppercase text-red-500 md:text-center">
            {driversBySector.mostRepeatedWord}
          </p>
          <p>La palabra que más se repite</p>
        </div>

        <div
          lang="es"
          className="hyphens-auto text-md text-center md:text-left mt-10 md:w-1/2 grid lg:grid-cols-2 m-auto font-semibold border-2 border-neutral-500 bg-zinc-50 py-5 px-2 md:px-10 md:text-xl items-center">
          <div className="uppercase text-red-500 md:text-center">
            {driversBySector.outstandingFeature.map((feature) => (
              <p key={feature}>{feature}</p>
            ))}
          </div>
          <p>Característica sobresaliente</p>
        </div>
      </section>

      <section className="mt-5 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Arquetipos culturales por áreas
        </p>

        <ResponsiveContainer width={'100%'} height={500}>
          <RadarChart outerRadius="90%" data={companyArchetypes}>
            <PolarGrid gridType="circle" />
            <PolarAngleAxis radius={50} fontWeight={600} dataKey="name" />
            <PolarRadiusAxis angle={45} domain={[0, 50]} />
            {_companyArchetypes.newDatasets.map((dataset, i) => (
              <Radar
                key={dataset.label}
                name={dataset.label}
                dataKey={dataset.label}
                stroke={COLORS[i]}
                strokeWidth={2}
                fill="transparent"
              />
            ))}
            <Legend />
          </RadarChart>
        </ResponsiveContainer>
      </section>
    </>
  );
};

WordPlayResults.propTypes = {
  stats: PropTypes.object
};
