import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip
} from 'recharts';
import { chain, get } from 'lodash';
import capitalize from 'lodash/capitalize';
import { useSelector } from 'react-redux';
import { CustomTooltip } from '../../../utils/customTooltip';
import { useScreen } from '../../../hooks/useScreen';

export const COLORS = [
  '#3d4c5cff',
  '#2f6383ff',
  '#80a8b3ff',
  '#e93d3dff',
  '#3f7ee9ff',
  '#488a6fff',
  '#e46309ff',
  '#e4da09ff',
  '#e9218eff',
  '#21bde9ff',
  '#e92121ff',
  '#21e94eff',
  '#e9e921ff',
  '#e921e9ff',
  '#21e9e9ff',
  '#e9e9e9ff'
];

export const Composition = ({ stats }) => {
  const { isMobile, isTablet, isLaptop } = useScreen();
  const { userCompanyName } = useSelector((state) => state.user);

  const totalParticipants = get(stats, 'composition.participants', 0);
  const genderData = chain(stats)
    .get('composition.byGender', {})
    .mapKeys((_, key) => capitalize(key))
    .mapValues((value, key) => ({ name: key, value }))
    .values()
    .filter(({ value }) => value > 0)
    .value();
  const totalByGender = genderData.reduce((acc, { value }) => acc + value, 0);
  const sectorData = get(stats, 'composition.bySector', null).filter(({ value }) => value > 0);
  const regionData = get(stats, 'composition.byRegion', null).filter(({ value }) => value > 0);
  const generationData = get(stats, 'composition.byGeneration', []).filter(
    ({ value }) => value > 0
  );
  const totalByGeneration = generationData.reduce((acc, { value }) => acc + value, 0);
  const educationData = get(stats, 'composition.byEducationLevel', null).filter(
    ({ value }) => value > 0
  );
  const seniorityData = get(stats, 'composition.bySeniority', null).filter(
    ({ value }) => value > 0
  );
  const dependantsData = get(stats, 'composition.byDependants', null).filter(
    ({ value }) => value > 0
  );
  const totalByDependants = dependantsData.reduce((acc, { value }) => acc + value, 0);
  const yearsData = get(stats, 'composition.byYearsOfExp', null).filter(({ value }) => value > 0);

  const pieOuterRadius = isMobile ? '40%' : '80%';
  const height = isMobile ? 300 : 400;
  const graphsFontSize = isMobile ? '10' : isTablet && !isLaptop ? '12' : '14';

  return (
    <section id="Composition">
      <p className="uppercase text-primary font-bold text-2xl">Composición de la muestra</p>
      <div className="mt-6 text-primary flex flex-col md:flex-row">
        <p className="text-2xl flex-1 font-medium">{userCompanyName}</p>
        <p className="text-base">
          Tamaño total de la muestra:{' '}
          <span className="font-medium">{totalParticipants} personas</span>
        </p>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-16 mt-10">
        <div>
          <p className="font-semibold text-base text-center md:mb-10">Sexo Biológico</p>
          <ResponsiveContainer width={'100%'} height={height}>
            <PieChart className="m-auto">
              <Pie
                outerRadius={pieOuterRadius}
                label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                  const RADIAN = Math.PI / 180;
                  const radius = 25 + innerRadius + (outerRadius - innerRadius);
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      textAnchor={x > cx ? 'start' : 'end'}
                      dominantBaseline="central">
                      <tspan fill="black" alignmentBaseline="middle" fontSize={graphsFontSize}>
                        {genderData[index].name}
                      </tspan>
                      <tspan fill="#878786" fontSize={graphsFontSize} x={x} y={y + 20}>
                        {totalByGender > 0 ? ((value / totalByGender) * 100).toFixed(1) : 0}%
                      </tspan>
                    </text>
                  );
                }}
                data={genderData}
                cx="50%"
                cy="50%"
                dataKey="value">
                {genderData.map((data, i) => (
                  <Cell key={`${data.name}${data.value}`} fill={COLORS[i]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Áreas de la organización</p>
          {sectorData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={sectorData}
                margin={{ top: 0, right: 0, bottom: 0, left: 50 }}>
                <CartesianGrid horizontal={false} />
                <XAxis
                  tickFormatter={(value) => `${value}%`}
                  domain={isMobile ? undefined : [0, 100]}
                  type="number"
                  tickLine={false}
                />
                <YAxis dataKey="name" fontSize={graphsFontSize} type="category" tickLine={false} />
                <Tooltip content={CustomTooltip.PERCENTAGE} />
                <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Regiones</p>
          {regionData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={regionData}
                margin={{ top: 0, right: 0, bottom: 0, left: 50 }}>
                <CartesianGrid horizontal={false} />
                <XAxis
                  tickFormatter={(value) => `${value}%`}
                  domain={isMobile ? undefined : [0, 100]}
                  type="number"
                  tickLine={false}
                />
                <YAxis dataKey="name" fontSize={graphsFontSize} type="category" tickLine={false} />
                <Tooltip content={CustomTooltip.PERCENTAGE} />
                <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Generaciones</p>
          {generationData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <PieChart className="m-auto">
                <Pie
                  outerRadius={pieOuterRadius}
                  label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                    const RADIAN = Math.PI / 180;
                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor={x > cx ? 'start' : 'end'}
                        dominantBaseline="central">
                        <tspan fill="black" alignmentBaseline="middle" fontSize={graphsFontSize}>
                          &nbsp;&nbsp;{generationData[index].name}&nbsp;&nbsp;
                        </tspan>
                        <tspan fill="#878786" fontSize={graphsFontSize} x={x} y={y + 20}>
                          &nbsp;&nbsp;
                          {totalByGeneration > 0
                            ? ((value / totalByGeneration) * 100).toFixed(1)
                            : 0}
                          %&nbsp;&nbsp;
                        </tspan>
                      </text>
                    );
                  }}
                  data={generationData}
                  cx="50%"
                  cy="50%"
                  dataKey="value">
                  {generationData.map((data, i) => (
                    <Cell key={`${data.name}${data.value}`} fill={COLORS[i]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Nivel de Educación</p>
          {educationData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={educationData}
                margin={{ top: 0, right: 0, bottom: 0, left: 50 }}>
                <CartesianGrid horizontal={false} />
                <XAxis
                  tickFormatter={(value) => `${value}%`}
                  domain={isMobile ? undefined : [0, 100]}
                  type="number"
                  tickLine={false}
                />
                <YAxis dataKey="name" fontSize={graphsFontSize} type="category" tickLine={false} />
                <Tooltip content={CustomTooltip.PERCENTAGE} />
                <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Antigüedad en la organización</p>
          {yearsData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={yearsData}
                margin={{ top: 0, right: 0, bottom: 0, left: 50 }}>
                <CartesianGrid horizontal={false} />
                <XAxis
                  tickFormatter={(value) => `${value}%`}
                  domain={isMobile ? undefined : [0, 100]}
                  type="number"
                  tickLine={false}
                />
                <YAxis dataKey="name" fontSize={graphsFontSize} type="category" tickLine={false} />
                <Tooltip content={CustomTooltip.PERCENTAGE} />
                <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Personas a Cargo</p>
          {dependantsData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <PieChart className="m-auto">
                <Pie
                  outerRadius={pieOuterRadius}
                  label={({ cx, cy, midAngle, innerRadius, outerRadius, value, index }) => {
                    const RADIAN = Math.PI / 180;
                    const radius = 25 + innerRadius + (outerRadius - innerRadius);
                    const x = cx + radius * Math.cos(-midAngle * RADIAN);
                    const y = cy + radius * Math.sin(-midAngle * RADIAN);

                    return (
                      <text
                        x={x}
                        y={y}
                        textAnchor={x > cx ? 'start' : 'end'}
                        dominantBaseline="central">
                        <tspan fill="black" alignmentBaseline="middle" fontSize={graphsFontSize}>
                          {dependantsData[index].name}
                        </tspan>
                        <tspan fill="#878786" fontSize={graphsFontSize} x={x} y={y + 20}>
                          {totalByDependants > 0
                            ? ((value / totalByDependants) * 100).toFixed(1)
                            : 0}
                          %
                        </tspan>
                      </text>
                    );
                  }}
                  data={dependantsData}
                  cx="50%"
                  cy="50%"
                  dataKey="value">
                  {dependantsData.map((data, i) => (
                    <Cell key={`${data.name}${data.value}`} fill={COLORS[i]} />
                  ))}
                </Pie>
              </PieChart>
            </ResponsiveContainer>
          )}
        </div>

        <div>
          <p className="font-semibold text-base text-center mb-10">Nivel de Decisión</p>
          {seniorityData && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={seniorityData}
                margin={{ top: 0, right: 0, bottom: 0, left: 50 }}>
                <CartesianGrid horizontal={false} />
                <XAxis
                  tickFormatter={(value) => `${value}%`}
                  domain={isMobile ? undefined : [0, 100]}
                  type="number"
                  tickLine={false}
                />
                <YAxis dataKey="name" fontSize={graphsFontSize} type="category" tickLine={false} />
                <Tooltip content={CustomTooltip.PERCENTAGE} />
                <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35} />
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>
      </div>
    </section>
  );
};

Composition.propTypes = {
  stats: PropTypes.object
};
