import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Notification } from '../../components';
import { Composition } from './CompanyStatsSections/Composition';
import { AutoReplyResults } from './CompanyStatsSections/AutoReplyResults';
import { WordPlayResults } from './CompanyStatsSections/WordPlayResults';
import { PermeabilityResults } from './CompanyStatsSections/PermeabilityResults';
import { isEmpty } from 'lodash';

const AdminStats = ({ companies, setCompanyFilter, apiError, loading, stats }) => {
  const companyOptions = [
    ...companies.map((company) => ({
      value: company._id,
      label: company.name
    }))
  ];

  // add {value: 'all', label: 'Todas las empresas'} to the companyOptions
  companyOptions.unshift({ value: 'all', label: 'Todas las empresas' });
  // const { userCompanyName } = useSelector((state) => state.user);

  return (
    <div className="w-full ">
      <div>
        <div className=" rounded-lg py-4 mb-3 pr-12 flex flex-col gap-x-4 sm:flex-row justify-left">
          <p className="text-secondary py-2">Selecciona empresa</p>
          {companies.length > 0 ? (
            <Select
              className="outline-none border-none w-56 marker:outline-none"
              styles={{
                input: (base) => ({
                  ...base,
                  'input:focus': {
                    boxShadow: 'none'
                  }
                })
              }}
              defaultValue={companyOptions[1]}
              isSearchable
              name="company"
              options={companyOptions}
              onChange={(e) => {
                setCompanyFilter(e.value);
              }}
            />
          ) : null}
        </div>
        {!apiError && !loading && !isEmpty(stats) ? (
          <>
            <Composition stats={stats} />
            <AutoReplyResults stats={stats} />
            <WordPlayResults stats={stats} />
            <PermeabilityResults stats={stats} />
          </>
        ) : apiError ? (
          <Notification type="error" message={apiError} />
        ) : null}
      </div>
    </div>
  );
};

export default AdminStats;

AdminStats.propTypes = {
  companies: PropTypes.array,
  setCompanyFilter: PropTypes.func,
  companyFilter: PropTypes.string,
  apiError: PropTypes.string,
  globalRA: PropTypes.number,
  efectoRAarea: PropTypes.array,
  efectoRAnivelDeDecision: PropTypes.array,
  womenValue: PropTypes.number,
  menValue: PropTypes.number,
  efectoRAHSvsFM: PropTypes.number,
  efectoRACCvsFM: PropTypes.number,
  efectoRAAverage: PropTypes.number,
  loading: PropTypes.bool,
  stats: PropTypes.object
};
