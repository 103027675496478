import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { getIconStatus } from '../../utils/getIconStatus';
import axios from '../../config/axiosInterceptor';

const AddMultipleParticipantsRow = ({ email, index, totalRows }) => {
  const [status, setStatus] = useState('loading');
  const [error, setError] = useState(null);

  const addParticipant = async () => {
    try {
      await axios.post('/admin/participants', {
        email: email.trim()
      });
      setStatus('success');
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setError(error.response.data.error || error.message);
      setStatus('error');
    }
  };

  useEffect(() => {
    addParticipant();
  }, []);

  return (
    <tr className="flex flex-col sm:flex-row sm:w-full ">
      <td
        className={`border-r border-b p-6 pl-3 bg-light_gray sm:bg-white truncate w-full text-sm ${
          index === 0
            ? 'rounded-t-lg sm:rounded-none'
            : index === totalRows
            ? 'sm:rounded-bl-lg sm:rounded-br-none'
            : ''
        }`}>
        {email}
      </td>
      <td
        className={`border-r border-b p-6 pl-3 truncate w-full text-sm ${
          index === totalRows ? 'rounded-b-lg sm:rounded-br-lg sm:rounded-bl-none' : ''
        }`}>
        {getIconStatus(status, error)}
      </td>
    </tr>
  );
};

export default AddMultipleParticipantsRow;

AddMultipleParticipantsRow.propTypes = {
  email: PropTypes.string.isRequired,
  index: PropTypes.number,
  totalRows: PropTypes.number
};
