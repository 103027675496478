import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  // eslint-disable-next-line no-undef
  isSuperAdmin: undefined,
  userCompany: undefined,
  userCompanyName: undefined,
  userEmail: '',
  userName: ''
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setIsSuperAdmin(state, action) {
      state.isSuperAdmin = action.payload;
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setUserCompany(state, action) {
      state.userCompany = action.payload;
    },
    setUserCompanyName(state, action) {
      state.userCompanyName = action.payload;
    }
  }
});

export default userSlice.reducer;

export const { setIsSuperAdmin, setUserEmail, setUserName, setUserCompany, setUserCompanyName } =
  userSlice.actions;
