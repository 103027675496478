export const parseDashboardValueLabel = (value) => {
  switch (value) {
    case 'companies':
      return value === 1 ? 'Empresa' : 'Empresas';
    case 'invitations':
      return value === 1 ? 'Invitación enviada' : 'Invitaciones enviadas';
    case 'gamesDone':
      return value === 1 ? 'Juego realizado' : 'Juegos realizados';
  }
};
