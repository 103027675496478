import PropTypes from 'prop-types';
import { first, get, isArray, isNil, mapKeys, mapValues, keys, uniq } from 'lodash';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  ComposedChart,
  Scatter,
  Line,
  Dot,
  Legend
} from 'recharts';
import WorldMap from 'react-svg-worldmap';

import ScaleChart from '../../../components/charts/ScaleChart';
import { getFixedNumber } from '../../../utils/getFixedNumber';
import genderM from '../../../assets/img/gender-m.svg';
import genderF from '../../../assets/img/gender-f.svg';
import { CustomTooltip } from '../../../utils/customTooltip';
import { countriesIsoCode } from '../../../constants/countries';
import { useScreen } from '../../../hooks/useScreen';

const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];

const cards = [
  {
    title: 'Elecciones automáticas',
    text: 'Tal como lo indica su nombre este juego muestra cómo respondemos y cuáles son las elecciones que hacemos de forma automática.'
  },
  {
    title: 'Creencias',
    text: 'Este tipo de respuesta muestra la forma en la que está modelado nuestro cerebro de acuerdo a las creencias profundas que adquirimos a lo largo de nuestra vida y que fueron creando en nuestra mente estereotipos culturales válidos.'
  },
  {
    title: 'Decisiones',
    text: 'Si tuviésemos la oportunidad de pensar, tal como podemos hacerlo en el día a día, quizá forzaríamos una elección distinta a nuestra respuesta automática debido a ciertas variables que podemos pensar y aislar de forma consciente para la toma de decisión. Esa es la magia del juego de respuesta automática: “Ser conscientes de cuáles son nuestras respuestas automáticas para estar atentos y ejercitar cambiarlas”'
  }
];

const SEGMENT_COLORS = [
  '#E53D3D',
  '#EAA73E',
  '#F8DA49',
  '#8DB944',
  '#F8DA49',
  '#EAA73E',
  '#E53D3D'
];

const byGenderAndSectorColors = ['#3D4C5C', '#E53D3D', '#288E8E'];

const findLineByLeastSquares = (values_x, values_y) => {
  var sum_x = 0;
  var sum_y = 0;
  var sum_xy = 0;
  var sum_xx = 0;
  var count = 0;

  /*
   * We'll use those variables for faster read/write access.
   */
  var x = 0;
  var y = 0;
  var values_length = values_x.length;

  if (values_length != values_y.length) {
    throw new Error('The parameters values_x and values_y need to have same size!');
  }

  /*
   * Nothing to do.
   */
  if (values_length === 0) {
    return [[], []];
  }

  /*
   * Calculate the sum for each of the parts necessary.
   */
  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = values_y[v];
    sum_x += x;
    sum_y += y;
    sum_xx += x * x;
    sum_xy += x * y;
    count++;
  }

  /*
   * Calculate m and b for the formular:
   * y = x * m + b
   */
  var m = (count * sum_xy - sum_x * sum_y) / (count * sum_xx - sum_x * sum_x);
  var b = sum_y / count - (m * sum_x) / count;

  /*
   * We will make the x and y result line now
   */
  var result_values_x = [];
  var result_values_y = [];

  for (let v = 0; v < values_length; v++) {
    x = values_x[v];
    y = x * m + b;
    result_values_x.push(x);
    result_values_y.push(y);
  }

  return [result_values_x, result_values_y];
};

const emptyAnswer = (value) => isNil(value) || value === 99 || (isArray(value) && isNil(value[0]));
const sanitizeValue = (stat) => ({
  ...stat,
  value: emptyAnswer(stat.value) ? null : stat.value
});

const getLineData = (stats) => {
  const validStats = stats
    .map((s, i) => {
      return {
        x: i,
        y: s.value
      };
    })
    .filter((s) => !emptyAnswer(s.y));

  const xAxis = findLineByLeastSquares(
    validStats.map((s) => s.x),
    validStats.map((s) => s.y)
  )[1];

  const results = xAxis.map((value, i) => ({
    name: stats[validStats[i].x].name,
    valueLine: value
  }));

  return results;
};

const mapJRAKeys = (effect) => {
  return {
    efectoCMHvsCFS: effect.efectoRAglobal,
    efectoCMvsCF: effect.efectoRACCvsFM,
    efectoMHvsFS: effect.efectoRAHSvsFM
  };
};

export const AutoReplyResults = ({ stats }) => {
  const { isMobile, isTablet, isLaptop } = useScreen();
  const totalParticipants = get(stats, 'composition.participants', 0);

  const {
    jra: { efectoRAAverage = 0, efectoRAglobal = 0, efectoRAHSvsFM = 0, efectoRACCvsFM = 0 }
  } = stats;

  const menData = stats.jra.efectoRAhombres;
  const womenData = stats.jra.efectoRAmujeres;
  const sectorData = mapValues(mapJRAKeys(stats.jra.efectoRAarea), (area) =>
    area
      .filter((stat) => !emptyAnswer(stat.value))
      .map((stat) => ({
        ...stat,
        value: first(stat.value)
      }))
      .map((stat) => mapKeys(stat, (_, key) => (key === 'area' ? 'name' : key)))
  );
  const generationData = mapValues(mapJRAKeys(stats.jra.efectoRAgeneracion), (generation) =>
    generation.filter((stat) => !emptyAnswer(stat.value))
  );
  const seniorityData = mapValues(mapJRAKeys(stats.jra.efectoRAnivelDeDecision), (value) =>
    value
      .filter((stat) => !emptyAnswer(stat.value))
      .map(sanitizeValue)
      .map((stat) => mapKeys(stat, (_, key) => (key === 'nivelDeDecision' ? 'name' : key)))
  );
  const seniorityLineData = {
    efectoCMHvsCFS: getLineData(seniorityData.efectoCMHvsCFS),
    efectoCMvsCF: getLineData(seniorityData.efectoCMvsCF),
    efectoMHvsFS: getLineData(seniorityData.efectoMHvsFS)
  };

  const countriesData = stats.jra.efectoRARegion.map((region) => {
    const country = countriesIsoCode.find(
      (country) => country.name.toLocaleLowerCase() === region.region.toLocaleLowerCase()
    );

    const regionData = {
      ...region,
      country: region.region.toLocaleLowerCase().slice(0, 2)
    };
    if (country) {
      regionData.country = country.code.toLocaleLowerCase();
    }

    return regionData;
  });

  const byGenderAndSector = (stats.jra.efectoRAAreaAndSex ?? []).map((stat) => {
    const transformedStat = {
      name: stat.area,
      values: stat.values
    };
    for (const key of keys(stat.values)) {
      transformedStat[key] = stat.values[key];
    }
    return transformedStat;
  });
  const byGenderAndSectorKeys = uniq(byGenderAndSector.map((stat) => keys(stat.values)).flat());

  const height = isMobile ? 300 : 400;
  const graphsFontSize = isMobile ? '10' : isTablet && !isLaptop ? '11' : '12';

  return (
    <>
      <section id="AutoReplyResults" className="mt-4 md:mt-10 py-10">
        <p className="uppercase text-primary font-bold text-2xl">
          RESULTADOS JUEGO DE RESPUESTA AUTOMÁTICA
        </p>
        <p className="mt-6 text-primary text-base text-right">
          Tamaño total de la muestra:{' '}
          <span className="font-medium">{totalParticipants} personas</span>
        </p>

        <div className="mt-10 grid grid-cols-1 lg:grid-cols-3 gap-x-5 gap-y-3 lg:gap-y-0">
          {cards.map((card, index) => (
            <div className="py-5 px-8 bg-gray-100 border-gray-500 rounded-md border-2" key={index}>
              <h2 className="text-xl font-semibold text-primary p-3">{card.title}</h2>
              <p className="text-primary p-3">{card.text}</p>
            </div>
          ))}
        </div>
        <div className="my-5 py-5 px-8 bg-gray-100 border-gray-500 rounded-md border-2">
          <p className="text-primary p-3">
            Llevar a la consciencia nuestras respuestas automáticas, llamadas y conocidas como
            Sesgos Implícitos, no sólo es fundamental para entender cómo actúa nuestro cerebro,
            aunque creamos que lo hemos superado, sino para comprender profundamente el valor que
            aporta un “otro” diferente y cuál es el espacio que le damos a quienes no piensan ni
            ejercen su liderazgo o función de la misma forma en la que lo harías tú o la que ha sido
            establecida.
          </p>
        </div>
      </section>
      <section>
        <p className="text-xl font-semibold text-primary">¿Cómo interpretar el resultado?</p>
        <p className="my-5">
          Efecto Respuesta Automática (RA) Representa el resultado general del juego en un rango de
          datos de -3 y +3, mostrando una tendencia. Los resultados de cada categoría del juego
          también serán representadas con el mismo rango de datos.
        </p>
        <div className="flex w-full md:my-5 relative md:max-w-4xl md:mx-auto justify-between">
          <ScaleChart hideNoDataWarning hideValue hideNeedle />
        </div>
        <div className="flex w-full justify-between">
          <p className="text-left text-xs sm:text-base md:text-lg md:text-left">
            C-Level-Masculino-Hard
            <br />
            Colaborador/a-Femenino-Soft
          </p>
          <p className="text-right text-xs sm:text-base md:text-lg">
            C-Level-Femenino-Hard
            <br />
            Colaborador/a-Masculino-Soft
          </p>
        </div>

        <p className="mt-20 md:mt-16 text-lg ">
          Tal como lo muestra el gráfico, el resultado negativo muestra una tendencia hacia lo
          Masculino-Hard y el resultado positivo hacia lo Femenino-Soft. Las tendencias se muestran
          como fuerte, moderada y leve y en el centro la tendencia es el equilibrio cuando el
          resultado es igual a cero.
        </p>
      </section>

      <section>
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Efecto Respuesta Automática
        </p>

        <p className="text-center font-medium w-2/4 m-auto">
          Verás en cada gráfico el resultado total de todas las personas que realizaron el juego de
          respuesta automático
        </p>

        <div className="my-8">
          <p className="text-center text-lg font-medium w-2/4 m-auto">
            Resultado general del juego
          </p>
          <div className="m-auto mt-3 w-28 h-14 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white flex justify-center items-center">
            <span>{getFixedNumber(efectoRAAverage) ?? 0}</span>
          </div>
        </div>

        <div className="flex justify-center gap-x-9 gap-y-4 flex-wrap">
          <ScaleChart
            title="Resultado de la Integración de todas las categorías del juego de Respuesta Automática"
            value={getFixedNumber(efectoRAglobal) ?? 0}
            customWidth="max-w-[460px]"
            showLabelColor={true}
          />
          <ScaleChart
            value={getFixedNumber(efectoRAHSvsFM) ?? 0}
            title="Resultados categorías: Hard y Soft vs Femenino y Masculino"
            customWidth="max-w-[460px]"
            showLabelColor={true}
          />
          <ScaleChart
            value={getFixedNumber(efectoRACCvsFM) ?? 0}
            title=" Resultado categorías: C- Level- Colaborador/a vs.  Femenino - Masculino"
            customWidth="max-w-[460px]"
            showLabelColor={true}
          />
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Sexo Biológico
        </p>

        <p className="text-center font-medium w-2/4 m-auto">
          Te mostramos los resultados de cada categoría discriminadas por sexo biológico
        </p>

        <div className="grid lg:grid-cols-2 mt-16">
          <div>
            <div className="text-center my-10">
              <img className="w-28 m-auto" src={genderF} />
            </div>
            <div className="flex justify-center gap-x-9 gap-y-4 flex-wrap">
              <ScaleChart
                title="Resultado de la Integración de todas las categorías del juego de Respuesta Automática"
                customWidth="max-w-[460px]"
                showLabelColor={true}
                hideNeedle={emptyAnswer(womenData.efectoRAglobal)}
                value={getFixedNumber(womenData.efectoRAglobal)}
                label={emptyAnswer(womenData.efectoRAglobal) ? 'N/A' : undefined}
              />
              <ScaleChart
                title="Resultados categorías: Hard y Soft vs Femenino y Masculino"
                customWidth="max-w-[460px]"
                showLabelColor={true}
                hideNeedle={emptyAnswer(womenData.efectoRAHSvsFM)}
                value={getFixedNumber(womenData.efectoRAHSvsFM)}
                label={emptyAnswer(womenData.efectoRAHSvsFM) ? 'N/A' : undefined}
              />
              <ScaleChart
                title=" Resultado categorías: C- Level- Colaborador/a vs.  Femenino - Masculino"
                customWidth="max-w-[460px]"
                showLabelColor={true}
                hideNeedle={emptyAnswer(womenData.efectoRACCvsFM)}
                value={getFixedNumber(womenData.efectoRACCvsFM)}
                label={emptyAnswer(womenData.efectoRACCvsFM) ? 'N/A' : undefined}
              />
            </div>
          </div>
          <div>
            <div className="text-center my-10">
              <img className="w-28 m-auto" src={genderM} />
            </div>
            <div className="flex justify-center gap-x-9 gap-y-4 flex-wrap">
              <ScaleChart
                title="Resultado de la Integración de todas las categorías del juego de Respuesta Automática"
                hideNeedle={emptyAnswer(menData.efectoRAglobal)}
                value={getFixedNumber(menData.efectoRAglobal)}
                label={emptyAnswer(menData.efectoRAglobal) ? 'N/A' : undefined}
                customWidth="max-w-[460px]"
                showLabelColor={true}
              />
              <ScaleChart
                hideNeedle={emptyAnswer(menData.efectoRAHSvsFM)}
                value={getFixedNumber(menData.efectoRAHSvsFM)}
                label={emptyAnswer(menData.efectoRAHSvsFM) ? 'N/A' : undefined}
                title="Resultados categorías: Hard y Soft vs Femenino y Masculino"
                customWidth="max-w-[460px]"
                showLabelColor={true}
              />
              <ScaleChart
                hideNeedle={emptyAnswer(menData.efectoRACCvsFM)}
                value={getFixedNumber(menData.efectoRACCvsFM)}
                label={emptyAnswer(menData.efectoRACCvsFM) ? 'N/A' : undefined}
                title=" Resultado categorías: C- Level- Colaborador/a vs.  Femenino - Masculino"
                customWidth="max-w-[460px]"
                showLabelColor={true}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Áreas de la organización
        </p>

        <p className="text-center font-medium w-2/4 m-auto">
          ¿Cómo son los sesgos implícitos en cada una de las áreas que realizaron el juego de
          respuesta automática?
        </p>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino-Hard vs Colaborador/a-Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {sectorData.efectoCMHvsCFS && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={sectorData.efectoCMHvsCFS}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {sectorData.efectoCMHvsCFS.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino vs Colaborador/a-Femenino
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {sectorData.efectoCMvsCF && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={sectorData.efectoCMvsCF}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis
                    fontSize={graphsFontSize}
                    domain={[-3, 3]}
                    tickCount={13}
                    type="number"
                    tickLine={false}
                  />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {sectorData.efectoCMvsCF.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de Masculino-Hard vs Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {sectorData.efectoMHvsFS && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={sectorData.efectoMHvsFS}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {sectorData.efectoMHvsFS.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Generaciones
        </p>

        <p className="text-center font-medium w-2/4 m-auto">
          ¿Cómo son los sesgos implícitos por generación?
        </p>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino-Hard vs Colaborador/a-Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {generationData.efectoCMHvsCFS && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={generationData.efectoCMHvsCFS}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {generationData.efectoCMHvsCFS.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino vs Colaborador/a-Femenino
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {generationData.efectoCMvsCF && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={generationData.efectoCMvsCF}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {generationData.efectoCMvsCF.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de Masculino-Hard vs Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {generationData.efectoMHvsFS && (
              <ResponsiveContainer width={'100%'} height={height}>
                <BarChart
                  layout="vertical"
                  data={generationData.efectoMHvsFS}
                  margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                  <CartesianGrid horizontal={false} />
                  <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                  <YAxis
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    tickLine={false}
                  />
                  <Tooltip content={CustomTooltip.NUMBER} />
                  <Bar dataKey="value" fill="#3e4d5c" maxBarSize={35}>
                    {generationData.efectoMHvsFS.map((entry) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return (
                        <Cell
                          key={`cell-${entry.value}-${entry.name}`}
                          fill={labelBackgroundColor}
                        />
                      );
                    })}
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Nivel de Decisión
        </p>

        <p className="text-center font-medium w-2/4 m-auto">
          ¿Cómo son los sesgos implícitos por nivel de decisión?
        </p>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino-Hard vs Colaborador/a-Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {seniorityData.efectoCMHvsCFS && (
              <ResponsiveContainer width={'100%'} height={500}>
                <ComposedChart data={seniorityData.efectoCMHvsCFS} margin={{ left: 20, right: 20 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    height={180}
                    angle={-60}
                    interval={0}
                    dataKey="name"
                    type="category"
                    textAnchor="end"
                    padding={{ left: 20, right: 20 }}
                    fontSize={graphsFontSize}
                  />
                  <YAxis
                    tickCount={13}
                    dataKey="value"
                    domain={[-3, 3]}
                    type="number"
                    tickLine={false}
                  />
                  <Line
                    strokeWidth={3}
                    data={seniorityLineData.efectoCMHvsCFS}
                    dataKey="valueLine"
                    stroke="#80A4AF"
                    dot={false}
                    activeDot={false}
                    legendType="none"
                  />
                  <Scatter
                    dataKey="value"
                    shape={({ cx, cy, fill }) => {
                      return <Dot cx={cx} cy={cy} fill={fill} r={15} />;
                    }}>
                    {seniorityData.efectoCMHvsCFS.map((entry, i) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return <Cell key={i} fill={labelBackgroundColor} />;
                    })}
                  </Scatter>
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de C-Level-Masculino vs Colaborador/a-Femenino
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {seniorityData.efectoCMvsCF && (
              <ResponsiveContainer width={'100%'} height={500}>
                <ComposedChart data={seniorityData.efectoCMvsCF} margin={{ left: 20, right: 20 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    height={180}
                    angle={-60}
                    interval={0}
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    textAnchor="end"
                    padding={{ left: 20, right: 20 }}
                  />
                  <YAxis
                    tickCount={13}
                    dataKey="value"
                    domain={[-3, 3]}
                    type="number"
                    tickLine={false}
                  />
                  <Line
                    strokeWidth={3}
                    data={seniorityLineData.efectoCMvsCF}
                    dataKey="valueLine"
                    stroke="#80A4AF"
                    dot={false}
                    activeDot={false}
                    legendType="none"
                  />
                  <Scatter
                    name="red"
                    dataKey="value"
                    fill="red"
                    shape={({ cx, cy, fill }) => {
                      return <Dot cx={cx} cy={cy} fill={fill} r={15} />;
                    }}>
                    {seniorityData.efectoCMvsCF.map((entry, i) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return <Cell key={i} fill={labelBackgroundColor} />;
                    })}
                  </Scatter>
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row items-center mt-20">
          <div className="md:w-2/6">
            <p className="md:w-1/2">
              Resultado para: integración de Masculino-Hard vs Femenino-Soft
            </p>
          </div>
          <div className="w-full md:w-4/6">
            {seniorityData.efectoMHvsFS && (
              <ResponsiveContainer width={'100%'} height={500}>
                <ComposedChart data={seniorityData.efectoMHvsFS} margin={{ left: 20, right: 20 }}>
                  <CartesianGrid vertical={false} />
                  <XAxis
                    height={180}
                    angle={-60}
                    interval={0}
                    fontSize={graphsFontSize}
                    dataKey="name"
                    type="category"
                    textAnchor="end"
                    padding={{ left: 20, right: 20 }}
                  />
                  <YAxis
                    tickCount={13}
                    dataKey="value"
                    domain={[-3, 3]}
                    type="number"
                    tickLine={false}
                  />
                  <Line
                    strokeWidth={3}
                    data={seniorityLineData.efectoMHvsFS}
                    dataKey="valueLine"
                    stroke="#80A4AF"
                    dot={false}
                    activeDot={false}
                    legendType="none"
                  />
                  <Scatter
                    name="red"
                    dataKey="value"
                    fill="red"
                    shape={({ cx, cy, fill }) => {
                      return <Dot cx={cx} cy={cy} fill={fill} r={15} />;
                    }}>
                    {seniorityData.efectoMHvsFS.map((entry, i) => {
                      const sanitizedValue = entry.value;
                      const segments = [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
                      let labelBackgroundColor = '';
                      if (sanitizedValue <= -3) {
                        labelBackgroundColor = '#E53D3D';
                      } else if (sanitizedValue >= 3) {
                        labelBackgroundColor = '#E53D3D';
                      } else {
                        for (let i = 0; i < segments.length; i++) {
                          const segment = segments[i];
                          if (sanitizedValue < segment) {
                            labelBackgroundColor = SEGMENT_COLORS[i - 1];
                            break;
                          }
                        }
                      }
                      return <Cell key={i} fill={labelBackgroundColor} />;
                    })}
                  </Scatter>
                </ComposedChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Regiones Geográficas
        </p>

        <p className="text-center font-medium md:w-2/4 m-auto">
          ¿Cómo son los sesgos implícitos por países o regiones geográficas?
        </p>

        <div className="mt-10 flex justify-center">
          <WorldMap
            className="m-auto"
            color="red"
            value-suffix="people"
            size="xl"
            data={countriesData}
            styleFunction={({ countryName }) => {
              let labelBackgroundColor = '#f3f4f6';

              const _country = countriesData.find((country) => country.region === countryName);
              if (_country) {
                const sanitizedValue = _country.value;
                if (sanitizedValue <= -3) {
                  labelBackgroundColor = '#E53D3D';
                } else if (sanitizedValue >= 3) {
                  labelBackgroundColor = '#E53D3D';
                } else {
                  for (let i = 0; i < segments.length; i++) {
                    const segment = segments[i];
                    if (sanitizedValue < segment) {
                      labelBackgroundColor = SEGMENT_COLORS[i - 1];
                      break;
                    }
                  }
                }
              }

              return {
                fill: labelBackgroundColor,
                fillOpacity: 1,
                stroke: '#000',
                strokeWidth: 1,
                strokeOpacity: 0.2,
                cursor: 'pointer'
              };
            }}
          />
        </div>
      </section>

      <section className="mt-10 md:mt-24">
        <p className="my-12 p-10 uppercase rounded-md font-semibold bg-secondary text-2xl text-center text-white">
          Áreas de la organización y sexo biológico, combinados
        </p>

        <div className="w-full md:w-2/3 m-auto text-center">
          {byGenderAndSector && (
            <ResponsiveContainer width={'100%'} height={height}>
              <BarChart
                layout="vertical"
                data={byGenderAndSector}
                margin={{ top: 0, right: 0, bottom: 0, left: 30 }}>
                <Legend
                  verticalAlign="top"
                  wrapperStyle={{
                    paddingBottom: '20px',
                    textTransform: 'capitalize'
                  }}
                />
                <CartesianGrid horizontal={false} />
                <XAxis domain={[-3, 3]} tickCount={13} type="number" tickLine={false} />
                <YAxis fontSize={graphsFontSize} dataKey="name" type="category" tickLine={false} />
                <Tooltip
                  content={({ active, payload }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="custom-tooltip py-3 px-5 bg-white border-2 border-gray-500">
                          {payload.map((p) => (
                            <p key={p.name} className="capitalize label">{`${p.name}: ${Number(
                              p.value
                            ).toFixed(2)}`}</p>
                          ))}
                        </div>
                      );
                    }

                    return null;
                  }}
                />
                {byGenderAndSectorKeys.map((key, i) => {
                  return (
                    <Bar
                      key={key}
                      dataKey={key}
                      fill={byGenderAndSectorColors[i]}
                      maxBarSize={35}
                    />
                  );
                })}
              </BarChart>
            </ResponsiveContainer>
          )}
        </div>

        <p className="mt-14 text-center font-semibold w-full md:w-1/3 m-auto">
          Recuerda que cuando el resultado es negativo la tendencia automática es hacia
          C-level-Masculino-Hard y Colaborador-Femenino-Soft. Cuando el resultado es positivo la
          tendencia es hacia C-level-Femenino que puede ser hard o soft
        </p>
      </section>
    </>
  );
};

AutoReplyResults.propTypes = {
  stats: PropTypes.object
};
