import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import AdminStats from '../../containers/stats/AdminStats';
import CompanyStats from '../../containers/stats/CompanyStats';
import {
  fetchCompanies,
  getAdminStats,
  getCompanyStats
} from '../../containers/participants/fetchingFunctions';
import { Loader } from '../../components';
import { isEmpty } from 'lodash';

const Stats = () => {
  const { isSuperAdmin } = useSelector((state) => state.user);
  const [companies, setCompanies] = useState([]);
  const [companyStats, setCompanyStats] = useState({});
  const [adminStats, setAdminStats] = useState({});
  const [companyFilter, setCompanyFilter] = useState('');
  const [apiError, setApiError] = useState('');
  const [participantsQuantityError, setParticipantsQuantityError] = useState(false);
  const [loading, setLoading] = useState(true);

  const companyOptions = [
    ...companies.map((company) => ({
      value: company._id,
      label: company.name
    }))
  ];

  useEffect(() => {
    if (isSuperAdmin) {
      setLoading(true);
      if (companies.length === 0) {
        fetchCompanies(setCompanies);
      }
      if (companyFilter !== '') {
        setApiError('');
        getAdminStats(setAdminStats, companyFilter, setApiError).then(() => setLoading(false));
      }
    }
    if (isSuperAdmin === false) getCompanyStats(setCompanyStats, setParticipantsQuantityError);
  }, [isSuperAdmin, companyFilter]);

  useEffect(() => {
    if (companyOptions.length > 0 && companyFilter === '') {
      setCompanyFilter(companyOptions[0].value);
    }
  }, [companyOptions]);

  if (participantsQuantityError) {
    return (
      <div className="flex flex-col md:mt-10  px-8">
        <p className="text-xl">Estadísticas</p>
        <p className="text-xl text-red-500">
          No hay suficientes datos para mostrar las estadísticas porque la empresa tiene menos de 2
          participantes
        </p>
      </div>
    );
  }
  if (
    (!isSuperAdmin && isEmpty(companyStats)) ||
    (isSuperAdmin && !apiError && isEmpty(adminStats))
  ) {
    return <Loader />;
  }

  return (
    <div className="flex flex-col px-0 mt-5 md:mt-10 xl:px-8">
      {isSuperAdmin ? (
        <>
          <p className="text-xl">Estadísticas</p>
          <AdminStats
            companies={companies}
            setCompanyFilter={setCompanyFilter}
            apiError={apiError}
            loading={loading}
            stats={adminStats}
          />
        </>
      ) : (
        <CompanyStats stats={companyStats} />
      )}
    </div>
  );
};

export default Stats;
