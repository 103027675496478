import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { XMarkIcon, Bars3Icon, Cog8ToothIcon } from '@heroicons/react/24/outline';
import { HashLink } from 'react-router-hash-link';
import cx from 'classnames';
import dashboardIcon from '../assets/icons/dashboard.svg';
import participantsIcon from '../assets/icons/participants.svg';
import companiesIcon from '../assets/icons/companies.svg';
import statsIcon from '../assets/icons/stats.svg';
import logoutIcon from '../assets/icons/logout.svg';

import logo from '../assets/img/logo.png';
import avatar from '../assets/img/avatar.png';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { logout } from '../utils/logout';
import { NAV_BAR_HEIGHT_MOBILE } from './Navbar/Navbar';
import { statsSections } from '../containers/stats/CompanyStatsSections/StatsNavbar';

/* eslint-disable no-undef */

const Sidebar = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [statsNavbarOpen, setStatsNavbarOpen] = useState(false);
  const { pathname, hash } = useLocation();

  const showStatsNavbarIcon = pathname.startsWith('/stats');
  // const [statesReseted, setStatesReseted] = useState(false);

  const { isSuperAdmin, userEmail, userName, userCompanyName } = useSelector((state) => state.user);

  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY);

  const navigation = [
    { name: 'Dashboard', to: '/', icon: dashboardIcon },
    { name: 'Participantes', to: '/participants', icon: participantsIcon },
    { name: 'Estadisticas', to: '/stats', icon: statsIcon }
  ];

  // If superAdmin, Empresas link is added in position 1
  isSuperAdmin &&
    navigation.splice(1, 0, { name: 'Empresas', to: '/companies', icon: companiesIcon });

  const activeClassName =
    'text-secondary group flex items-center px-2 py-2  text-base font-medium rounded-md';

  const handleLogout = async () => {
    logout(token);
    localStorage.removeItem(process.env.REACT_APP_LAST_ROUTE_KEY);
  };

  return (
    <div className="relative">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0">
            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full">
              <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0">
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => setSidebarOpen(false)}>
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                {/* mobile */}
                <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                  <div className="flex flex-shrink-0  border-gray-200 p-4">
                    <div className="flex items-center">
                      <div>
                        <img
                          className="inline-block h-16 w-16 min-w-[4rem] rounded-full"
                          draggable={false}
                          src={avatar}
                          alt="avatar"
                        />
                      </div>
                      <div className="ml-3 w-full">
                        <p className="text-md font-medium text-gray-700 truncate text-ellipsis overflow-hidden">
                          {userCompanyName}
                        </p>
                        <p className="text-sm font-medium text-options truncate ">{userName}</p>
                        <p className="text-sm font-medium text-options truncate ">{userEmail}</p>
                        <Link
                          to="/account"
                          onClick={() => setSidebarOpen(false)}
                          className={`${({ isActive }) =>
                            isActive
                              ? activeClassName
                              : undefined} mt-5 text-sm hover:text-secondary`}>
                          Mi cuenta
                        </Link>
                      </div>
                    </div>
                  </div>
                  <nav className="mt-5 space-y-1 border-b py-12 px-2">
                    {navigation.map((item) => (
                      <NavLink
                        onClick={() => setSidebarOpen(false)}
                        key={item.name}
                        to={item.to}
                        className={({ isActive }) =>
                          isActive
                            ? activeClassName
                            : 'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                        }>
                        <img
                          className="mr-4 h-6 w-6 text-secondary"
                          src={item.icon}
                          alt={item.name}
                        />
                        {item.name}
                      </NavLink>
                    ))}
                  </nav>
                  <div className="p-2 mx-2 flex items-center py-6">
                    <img className="mr-4 h-6 w-6" src={logoutIcon} alt="logout" />
                    <button onClick={handleLogout} className="font-medium text-base">
                      Cerrar sesión
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
            <div className="w-14 flex-shrink-0">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Transition.Root show={statsNavbarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-40 md:hidden" onClose={setStatsNavbarOpen}>
          <div
            className="fixed inset-0 z-50 flex bottom-auto"
            style={{ top: `${NAV_BAR_HEIGHT_MOBILE}px` }}>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-y-full"
              enterTo="translate-y-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full">
              <Dialog.Panel className="relative flex w-full flex-1 flex-col text-white uppercase bg-[#2A3847]">
                <div className="h-0 flex-1 overflow-y-auto my-5">
                  <nav className="px-5 font-normal text-sm">
                    {statsSections.map((section, i) => {
                      const isActive = hash.includes(section.href);
                      return (
                        <Fragment key={section.href}>
                          {i !== 0 && <div className="h-full border border-slate-500"></div>}
                          <HashLink
                            onClick={() => setStatsNavbarOpen(false)}
                            className="relative"
                            to={`/stats/${section.href}`}>
                            <div
                              className={cx([
                                'py-2',
                                {
                                  'font-semibold': isActive
                                }
                              ])}
                              key={section.href}
                              dangerouslySetInnerHTML={{ __html: section.label }}></div>
                          </HashLink>
                        </Fragment>
                      );
                    })}
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      {/* Static sidebar for desktop */}
      <div className="hidden md:absolute md:inset-y-0 md:flex md:w-64 md:flex-col">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex min-h-0 flex-1 flex-col border rounded-tr-lg border-gray-200 bg-gray-100">
          <div className="flex flex-1 flex-col overflow-y-auto  pb-4">
            <div className="flex flex-shrink-0  p-4">
              <div className="flex items-center">
                {/* <div>
                  <img
                    className="inline-block h-16 min-w-[4rem] w-16 rounded-full"
                    src={avatar}
                    alt="avatar"
                  />
                </div> */}
                <div className="ml-3 w-40">
                  <p className="text-md font-medium text-gray-700 truncate">{userCompanyName}</p>
                  <p className="text-sm font-medium text-options truncate">{userName}</p>
                  <p className="text-sm font-medium text-options truncate">{userEmail}</p>
                  <Link
                    to="/account"
                    onClick={() => setSidebarOpen(false)}
                    className="mt-5 text-sm font-bold hover:text-secondary ">
                    Mi cuenta
                  </Link>
                </div>
              </div>
            </div>
            <nav className="mt-5 space-y-1 border-b py-8 bg-gray-100 px-2">
              {navigation.map((item) => (
                <NavLink
                  onClick={() => setSidebarOpen(false)}
                  key={item.name}
                  to={item.to}
                  className={({ isActive }) =>
                    isActive
                      ? activeClassName
                      : 'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                  }>
                  <img
                    src={item.icon}
                    alt={item.name}
                    className={`${({ isActive }) =>
                      isActive ? 'text-secondary mr-4 h-6 w-6' : 'mr-4 h-6 w-6'} `}
                  />
                  {item.name}
                </NavLink>
              ))}
            </nav>

            {/* <div className="p-2 mx-2 flex items-center py-2">
              <img className="mr-4 h-6 w-6" src={resetIcon} alt="logout" />
              <button
                onClick={handleResetStats}
                className="font-medium text-red-700"
                disabled={statesReseted}>
                Limpiar Resultados
              </button>
            </div> */}
            <div className="p-2 mx-2 flex flex-1 items-start  py-4">
              <img className="mr-4 h-6 w-6" src={logoutIcon} alt="logout" />
              <button onClick={handleLogout} className="font-medium text-base">
                Cerrar sesión
              </button>
            </div>
          </div>
          <footer className="py-4">
            <p className="text-center">¿Tienes una consulta?</p>
            <a
              className="text-secondary block text-center hover:underline"
              href="mailto:clientes@humaninlab.com">
              clientes@humaninlab.com
            </a>
          </footer>
          {/* desktop */}
        </div>
      </div>
      <div
        style={{ marginTop: `-${NAV_BAR_HEIGHT_MOBILE}px` }}
        className="flex flex-1 flex-col md:pl-64">
        <div className="sticky flex w-screen top-0 z-10  pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
          <button
            type="button"
            className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
            onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6 text-white" aria-hidden="true" />
          </button>

          <img
            src={logo}
            draggable={false}
            className="h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 mt-3 -translate-y-1/2 hidden"
          />

          {showStatsNavbarIcon && (
            <button
              type="button"
              className="border-transparent focus:border-transparent focus:ring-0 -ml-0.5 absolute right-0 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
              onClick={() => setStatsNavbarOpen(true)}>
              <span className="sr-only">Open sidebar</span>
              <Cog8ToothIcon className="h-6 w-6 text-white" aria-hidden="true" />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
