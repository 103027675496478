import React from 'react';
import PropTypes from 'prop-types';

const Pagination = ({
  itemsPerPage,
  totalResults,
  nextPage,
  prevPage,
  currentPage,
  setResultsPerPage
}) => {
  return (
    <div className="py-2 flex sm:justify-end sm:gap-x-4">
      <p className="text-sm whitespace-nowrap">
        Resultados por página{' '}
        <select
          onChange={(e) => setResultsPerPage(parseInt(e.target.value))}
          className="border-none text-sm outline-none ring-0 ">
          <option value={10}>10</option>
          <option value={20}>20</option>
          <option value={50}>50</option>
        </select>
      </p>
      <div className="py-2">
        <p className="text-sm text-gray-700 whitespace-nowrap">
          <span className="font-medium">{currentPage * itemsPerPage - itemsPerPage}</span>
          {` - `}
          <span className="font-medium">
            {' '}
            {currentPage * itemsPerPage < totalResults
              ? currentPage * itemsPerPage
              : totalResults}{' '}
          </span>
          de
          <span className="font-medium"> {totalResults} </span>
        </p>
      </div>
      <div>
        <nav className="relative z-0 inline-flex rounded-md  -space-x-px" aria-label="Pagination">
          {currentPage * itemsPerPage > itemsPerPage && (
            <button
              onClick={() => {
                prevPage();
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span>{'<'}</span>
            </button>
          )}

          {currentPage * itemsPerPage < totalResults && (
            <button
              onClick={() => {
                nextPage();
              }}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md bg-white text-sm font-medium text-gray-500 hover:bg-gray-50">
              <span>{'>'}</span>
            </button>
          )}
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  totalResults: PropTypes.number,
  nextPage: PropTypes.func,
  prevPage: PropTypes.func,
  currentPage: PropTypes.number,
  setResultsPerPage: PropTypes.func
};
