import axios from '../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getDashboardRoute } from '../../utils/getDashboardRoute';
import { parseDashboardValueLabel } from '../../utils/parseDashboardValueLabel';

const Dashboard = () => {
  const navigate = useNavigate();

  const [dashboardStats, setDashboardStats] = useState({});

  const getDashboardStats = async () => {
    try {
      const response = await axios.get('/admin/dashboardStats');
      setDashboardStats(response.data);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    }
  };

  useEffect(() => {
    getDashboardStats();
  }, []);

  return (
    <div className="flex flex-col mt-5 md:mt-10 px-8">
      <p className="text-xl">Dashboard</p>

      <div className="grid grid-cols-1 gap-x-8 my-7 sm:grid-cols-2 lg:grid-cols-3">
        {Object.keys(dashboardStats).length > 0 &&
          Object.keys(dashboardStats).map((value) => (
            <div key={value}>
              <div
                className="border hover:cursor-pointer rounded-md min-w-full py-12 px-20 text-black"
                onClick={() => navigate(getDashboardRoute(value))}>
                <p className="text-6xl text-center font-bold">{dashboardStats[value]}</p>
              </div>
              <p className="py-3 text-center font-semibold text-secondary">
                {parseDashboardValueLabel(value)}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default Dashboard;
