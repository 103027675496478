import logo from '../../assets/img/logo.png';
import logoWhite from '../../assets/img/logo_white.svg';

import React, { useState } from 'react';

import { Input, Button, Notification } from '../../components/';
import { isValidEmail, validatePasswordLength } from '../../utils/validators';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../config/axiosInterceptor';

/* eslint-disable no-undef */
const Login = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [error, setError] = useState({
    email: '',
    password: ''
  });
  const [user, setUser] = useState({
    email: '',
    password: ''
  });

  const lastRoute = localStorage.getItem(process.env.REACT_APP_LAST_ROUTE_KEY) ?? '';
  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await axios.post('/auth/signin', user);
      localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY, res.data.token);
      localStorage.setItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_KEY, res.data.refreshToken);
      setLoading(false);
      lastRoute !== '' ? navigate(lastRoute) : navigate('/');
    } catch (error) {
      setLoading(false);
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      if (axios.isCancel(error)) setApiError('Ha ocurrido un error, intente nuevamente');
      setApiError(error.response.data.error || error.message);
    }
  };

  const loginIsValid = () => {
    return !error && user.email.trim() !== '' && user.password.trim() !== '' && !loading;
  };

  return (
    <div className="flex w-full min-h-screen">
      <div className="flex-1 bg-primary justify-center hidden md:flex">
        <img src={logoWhite} draggable={false} alt="humanin lab logo" className="w-1/3" />
      </div>
      <div className="flex-1 flex flex-col h-screen sm:h-auto  justify-between w-full py-5">
        <img src={logo} draggable={false} className="w-40 block mx-auto" alt="logo" />
        {lastRoute !== '' ? (
          <Notification type="success" message="Por seguridad, cerramos su sesion" />
        ) : null}
        <div className="px-12">
          <Input
            type="email"
            label="Nombre de usuario o correo electrónico *"
            name="user"
            onFocus={() => setApiError(null)}
            onChange={(e) => {
              setUser({ ...user, email: e.target.value });
              !isValidEmail(e.target.value)
                ? setError({ ...error, email: 'Email invalido' })
                : setError(null);
            }}
            showValidIcon={isValidEmail(user.email)}
            errors={error?.email}
          />

          <Input
            type="password"
            label="Contraseña / Password *"
            name="password"
            onChange={(e) => {
              setUser({ ...user, password: e.target.value });
              !validatePasswordLength(e.target.value)
                ? setError({
                    ...error,
                    password: 'la contraseña debe tener al menos 10 caracteres'
                  })
                : setError(null);
            }}
            errors={error?.password}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && loginIsValid()) {
                handleLogin(e);
              }
            }}
          />
          {apiError && <Notification className="mb-8" type="error" message={apiError} />}
          <Button isFullWidth onClick={handleLogin} loading={loading}>
            Ingresar
          </Button>
          <div className="text-secondary mt-5 underline">
            <Link to="/reset-password">Olvidé mi contraseña</Link>
          </div>
        </div>
        <footer>
          <p className="text-center">¿Tienes una consulta?</p>
          <a
            className="text-secondary block text-center hover:underline"
            href="mailto:clientes@humaninlab.com">
            clientes@humaninlab.com
          </a>
        </footer>
      </div>
    </div>
  );
};

export default Login;
