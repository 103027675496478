import React from 'react';
import ReactSpeedometer from 'react-d3-speedometer';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { first, last } from 'lodash';
import Logo from '../../assets/img/logo.png';
import Arrow from '../../assets/icons/long-arrow.svg';

const defaultSegmentLabels = [
  { text: 'Fuerte', position: 'OUTSIDE' },
  { text: 'Moderado', position: 'OUTSIDE' },
  { text: 'Leve', position: 'OUTSIDE' },
  { text: '', position: 'OUTSIDE' },
  { text: 'Leve', position: 'OUTSIDE' },
  { text: 'Moderado', position: 'OUTSIDE' },
  { text: 'Fuerte', position: 'OUTSIDE' }
];

const defaultResultTexts = [
  { text: 'Fuerte Alto', range: [Number.MIN_SAFE_INTEGER, -2.5] },
  { text: 'Fuerte Bajo', range: [-2.5, -2] },
  { text: 'Moderado Alto', range: [-2, -1.5] },
  { text: 'Moderado Bajo', range: [-1.5, -1] },
  { text: 'Leve Alto', range: [-1, -0.5] },
  { text: 'Leve Bajo', range: [-0.5, -0.1] },
  { text: 'Leve Bajo', range: [0.1, 0.5] },
  { text: 'Leve Alto', range: [0.5, 1] },
  { text: 'Moderado Bajo', range: [1, 1.5] },
  { text: 'Moderado Alto', range: [1.5, 2] },
  { text: 'Fuerte Bajo', range: [2, 2.5] },
  { text: 'Fuerte Alto', range: [2.5, Number.MAX_SAFE_INTEGER] }
];

// colors used by default
// 'bg-red-500',
// 'bg-amber-500',
// 'bg-yellow-300',
// 'bg-lime-500',
// 'bg-yellow-300',
// 'bg-amber-500',
// 'bg-red-500'
// 'text-red-500',
// 'text-amber-500',
// 'text-yellow-300',
// 'text-lime-500',
// 'text-yellow-300',
// 'text-amber-500',
// 'text-red-500'

const SEGMENT_COLORS = [
  'red-500',
  'amber-500',
  'yellow-300',
  'lime-500',
  'yellow-300',
  'amber-500',
  'red-500'
];

// eslint-disable-next-line react/prop-types
const ScaleChart = ({
  value,
  minValue,
  maxValue,
  customSegmentStops,
  customSegmentLabels,
  segmentColors,
  startColor,
  endColor,
  maxSegmentLabels,
  fluidWidth,
  valueTextFontSize,
  hideValue,
  hideNeedle,
  title,
  customWidth,
  titleClassName,
  label,
  showLabelColor,
  showCertification,
  hideNoDataWarning
}) => {
  const sanitizedMin = minValue ?? -3;
  const sanitizedMax = maxValue ?? 3;
  const _value = value ?? 99;
  let sanitizedValue = _value;
  if (sanitizedValue < sanitizedMin) {
    sanitizedValue = sanitizedMin;
  }
  if (sanitizedValue > sanitizedMax) {
    sanitizedValue = sanitizedMax;
  }
  const segments = customSegmentStops || [-3, -2, -1, -0.1, 0.1, 1, 2, 3];
  let labelBackgroundColor = 'secondary';
  const colors = segmentColors || SEGMENT_COLORS;
  if (showLabelColor) {
    if (sanitizedValue <= first(segments)) {
      labelBackgroundColor = first(colors);
    } else if (sanitizedValue >= last(segments)) {
      labelBackgroundColor = last(colors);
    } else {
      for (let i = 0; i < segments.length; i++) {
        const segment = segments[i];
        if (sanitizedValue < segment) {
          labelBackgroundColor = colors[i - 1];
          break;
        }
      }
    }
  }
  let labelColor = labelBackgroundColor;

  const isLabelColorHex = new RegExp(/[0-9A-Fa-f]{6}/g).test(labelBackgroundColor);
  const style = {};
  if (isLabelColorHex) {
    style.backgroundColor = labelBackgroundColor;
  } else {
    labelBackgroundColor = `bg-${labelBackgroundColor}`;
    labelColor = `text-${labelColor}`;
  }

  const valueText = defaultResultTexts.find((label) => {
    const [min, max] = label.range;
    return sanitizedValue >= min && sanitizedValue < max;
  })?.text;

  return (
    <div className={cx('flex flex-col max-auto', customWidth || 'w-full')}>
      {title && (
        <h3
          className={cx(
            'text-center font-normal mx-3 md:mx-auto text-primary text-lg my-5',
            titleClassName
          )}>
          {title}
        </h3>
      )}
      <div className="w-[300px] mx-auto mt-7">
        <div className={cx('relative', { 'pt-24': showCertification })}>
          <ReactSpeedometer
            segments={7}
            customSegmentLabels={customSegmentLabels || defaultSegmentLabels}
            minValue={sanitizedMin}
            maxValue={sanitizedMax}
            height={180}
            fluidWidth={fluidWidth}
            valueTextFontSize={valueTextFontSize}
            paddingVertical={valueTextFontSize ? 20 : 0}
            value={sanitizedValue}
            currentValueText={' '}
            maxSegmentLabels={maxSegmentLabels ?? 0}
            needleColor={hideNeedle ? 'transparent' : '#212529'}
            needleTransitionDuration={2000}
            needleTransition="easeElastic"
            paddingHorizontal={0}
            customSegmentStops={segments}
            startColor={startColor}
            endColor={endColor}
            segmentColors={
              segmentColors || [
                '#E53D3D',
                '#EAA73E',
                '#F8DA49',
                '#8DB944',
                '#F8DA49',
                '#EAA73E',
                '#E53D3D'
              ]
            }
          />

          {showCertification && (
            <div className="absolute top-0 w-full h-full">
              <div className="absolute top-8 right-0">
                <img className="w-20" src={Logo} />
                <p className="-mr-6 -mt-1 text-right text-xs font-semibold">Certificación</p>
              </div>
              <div className="absolute -rotate-[60deg] top-[37%] right-[13%] w-[100px]">
                <img src={Arrow} />
              </div>
            </div>
          )}
        </div>

        <div className="relative flex justify-center">
          <div className="w-full absolute -top-5 flex justify-between">
            <span>{sanitizedMin}</span>
            <span className="mx-auto">0</span>
            <span>{sanitizedMax}</span>
          </div>
        </div>

        {!hideValue && _value !== 99 && (
          <>
            <p
              style={{
                color: isLabelColorHex ? labelColor : null
              }}
              className={cx([
                'mt-3 text-sm text-center',
                {
                  [labelColor]: showLabelColor && !isLabelColorHex
                }
              ])}>
              {valueText}
            </p>
            <div
              style={style}
              className={cx([
                'm-auto mt-2 w-28 h-14 uppercase rounded-md font-semibold text-2xl text-center text-white flex justify-center items-center',
                {
                  [labelBackgroundColor]: showLabelColor && !isLabelColorHex,
                  'bg-secondary': !showLabelColor
                }
              ])}>
              <span>{label || value}</span>
            </div>
          </>
        )}

        {_value === 99 && !hideNoDataWarning && (
          <p className="text-secondary text-center text-sm mt-5">
            Aún no existen suficientes datos para mostrar resultados. Los resultados se muestran a
            partir de 2 respondientes de la misma categoría de datos.
          </p>
        )}
      </div>
    </div>
  );
};

export default ScaleChart;

ScaleChart.propTypes = {
  value: PropTypes.number || PropTypes.string,
  startColor: PropTypes.string,
  endColor: PropTypes.string,
  minValue: PropTypes.number,
  maxValue: PropTypes.number,
  customSegmentStops: PropTypes.array,
  customSegmentLabels: PropTypes.array,
  segmentColors: PropTypes.array,
  maxSegmentLabels: PropTypes.number,
  fluidWidth: PropTypes.bool,
  valueTextFontSize: PropTypes.number,
  hideValue: PropTypes.bool,
  hideNeedle: PropTypes.bool,
  title: PropTypes.string,
  customWidth: PropTypes.string,
  label: PropTypes.string,
  titleClassName: PropTypes.string,
  showLabelColor: PropTypes.bool,
  showCertification: PropTypes.bool,
  hideNoDataWarning: PropTypes.bool
};
