import axios from '../../../config/axiosInterceptor';
import React, { useState, useEffect } from 'react';
import { Input, Notification, Button } from '../../../components';
import { useSelector } from 'react-redux';

// eslint-disable-next-line react/prop-types
const AddAdminUser = ({ toggleModal, setSuccessCreatingUser }) => {
  const [adminUser, setAdminUser] = useState({
    email: '',
    name: ''
  });
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);

  const { userCompany } = useSelector((state) => state.user);

  const createAdminUser = async () => {
    setLoading(true);
    try {
      await axios.post(`/admin/adminUsers/${userCompany}`, {
        name: adminUser.name,
        email: adminUser.email
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setLoading(false);
      setApiError(error.response.data.error || error.message);
    }
  };

  const adminUserIsValid = () => {
    return adminUser.email.length > 0 && adminUser.name.length > 0 && !loading;
  };

  useEffect(() => {
    if (success) {
      toggleModal();
      setSuccessCreatingUser(true);
      const timer = setTimeout(() => {
        setSuccessCreatingUser(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [success, adminUser]);

  return (
    <div className="">
      <p className="text-secondary text-lg mb-7 font-semibold">Agregar usuario administrador</p>
      <p className="mb-2 font-semibold">Nombre *</p>
      <Input
        type="text"
        onFocus={() => setApiError(null)}
        value={adminUser.name}
        onChange={(e) => setAdminUser({ ...adminUser, name: e.target.value })}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && adminUserIsValid()) {
            createAdminUser();
          }
        }}
      />
      <p className="mb-2 font-semibold">Correo electrónico *</p>
      <Input
        type="email"
        showValidIcon={adminUserIsValid()}
        value={adminUser.email}
        onChange={(e) => setAdminUser({ ...adminUser, email: e.target.value })}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && adminUserIsValid()) {
            createAdminUser();
          }
        }}
      />
      {apiError ? <Notification type="error" message={apiError} /> : null}
      <Button
        mx="mx-auto sm:mx-0"
        disabled={!adminUserIsValid()}
        loading={loading}
        onClick={createAdminUser}>
        Agregar e invitar usuario
      </Button>
    </div>
  );
};

export default AddAdminUser;
