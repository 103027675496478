/* eslint-disable no-undef */
import axios from 'axios';
// import mem from 'mem';
import { logout } from './logout';

export const refreshToken = async () => {
  const refreshToken = localStorage.getItem(process.env.REACT_APP_REFRESH_TOKEN_STORAGE_KEY);
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY);

  if (!refreshToken) {
    logout(token);
    return;
  }

  try {
    const res = await axios.post('/auth/refresh', { refreshToken });

    if (res.status !== 200) {
      logout(token);
      return;
    }

    const { accessToken } = res.data;

    localStorage.setItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY, accessToken);
  } catch (error) {
    if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
    logout(token);
    localStorage.setItem(process.env.REACT_APP_LAST_ROUTE_KEY, window.location.pathname);
    return;
  }
};
// export const memoizedRefreshToken = mem(refreshToken, { maxAge: 10000 });
