export const getDashboardRoute = (value) => {
  switch (value) {
    case 'gamesDone':
      return '/stats';
    case 'invitations':
      return '/participants';
    case 'companies':
      return '/companies';
    default:
      return '/dashboard';
  }
};
