import React from 'react';
import ScaleChart from '../../components/charts/ScaleChart';
import { getFixedNumber } from '../../utils/getFixedNumber';

// eslint-disable-next-line react/prop-types
const PermeabilityStats = ({ data }) => {
  const customSegmentStops = [-300, -200, -100, 0, 100, 200, 300];

  const customSegmentLabels = [
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' },
    { text: '' }
  ];
  const segmentColors = ['#bc3a38', '#d44141', '#e8a394', '#f8da49', '#8cb944', '#00934b'];

  return (
    <div className="my-10 mt-16">
      <p className="font-semibold">
        ¿Qué tan permeable es la cultura de tu organización para que las mujeres llegue al C-level y
        con qué estilo/skills?
      </p>
      <br />
      <p>
        La permeabilidad de la cultura organizacional a DEI es un indicador que muestra si DEI se ha
        transformado o puede transformarse en un ADN de la cultura de tu organización.
      </p>
      <div>
        <ScaleChart
          customWidth="w-auto"
          customSegmentStops={customSegmentStops}
          customSegmentLabels={customSegmentLabels}
          segmentColors={segmentColors}
          value={getFixedNumber(data)}
          minValue={-300}
          maxValue={300}
          permeability
          showLabelColor
          showCertification
        />
      </div>

      <div className="flex justify-center mt-10">
        <div className="flex flex-col my-3  gap-3">
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#bc3a38]" />
            IMPERMEABLE
          </p>
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#d44141]" />
            MODERADAMENTE IMPERMEABLE
          </p>
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#e8a394]" />
            LEVEMENTE IMPERMEABLE
          </p>
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#f8da49]" />
            LEVEMENTE PERMEABLE
          </p>
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#8cb944]" />
            MODERADAMENTE PERMEABLE
          </p>
          <p className="flex gap-x-2 items-center mt-2">
            <span className="w-6 h-6 block bg-[#00934b]" />
            PERMEABLE
          </p>
        </div>
      </div>
    </div>
  );
};

export default PermeabilityStats;
