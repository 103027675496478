import React from 'react';
import PropTypes from 'prop-types';

import { Composition } from './CompanyStatsSections/Composition';
import { AutoReplyResults } from './CompanyStatsSections/AutoReplyResults';
import { WordPlayResults } from './CompanyStatsSections/WordPlayResults';
import { PermeabilityResults } from './CompanyStatsSections/PermeabilityResults';

const CompanyStats = ({ stats }) => {
  return (
    <div className="w-full">
      <Composition stats={stats} />
      <AutoReplyResults stats={stats} />
      <WordPlayResults stats={stats} />
      <PermeabilityResults stats={stats} />
    </div>
  );
};

export default CompanyStats;

CompanyStats.propTypes = {
  stats: PropTypes.object,
  womenValue: PropTypes.number,
  menValue: PropTypes.number,
  globalRA: PropTypes.number,
  efectoRAarea: PropTypes.array,
  efectoRAnivelDeDecision: PropTypes.array,
  efectoRAAverage: PropTypes.number,
  efectoRAHSvsFM: PropTypes.number,
  efectoRACCvsFM: PropTypes.number
};
