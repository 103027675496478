/* eslint-disable no-undef */
import axios from '../../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { Button, Input, Notification } from '../../../components';
import { useNavigate, useLocation } from 'react-router-dom';

import arrowIcon from '../../../assets/icons/arrow.svg';

// eslint-disable-next-line react/prop-types
const EditCompany = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const companyId = pathname.split('/')[2];

  const [companyName, setcompanyName] = useState();
  const [allowedParticipants, setAllowedParticipants] = useState();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);

  const updateCompany = async () => {
    setLoading(true);
    try {
      await axios.put(`/admin/companies/${companyId}`, {
        name: companyName,
        maxParticipants: allowedParticipants
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setLoading(false);
      setApiError(error.response.data.error || error.message);
    }
  };

  const companyIsValid = () => {
    return companyName.length > 0 && !loading;
  };

  const allowedParticipantsIsValid = () => {
    return allowedParticipants > 0 && !loading;
  };

  const getCompanyData = async () => {
    const company = await axios.get(`/admin/companies/${companyId}`);
    setcompanyName(company.data.name);
    setAllowedParticipants(company.data.maxParticipants);
  };

  useEffect(() => {
    if (companyId && !companyName) {
      getCompanyData();
    }

    if (success) {
      const timer = setTimeout(() => {
        setSuccess(null);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [companyId, success]);

  if (!companyName) return null;

  //   useEffect(() => {
  //     if (success) {
  //       setSuccessCreatingCompany(true);
  //       const timer = setTimeout(() => {
  //         setSuccessCreatingCompany(null);
  //       }, 3000);
  //       return () => clearTimeout(timer);
  //     }

  //     if (apiError) {
  //       if (companyName !== '') {
  //         setApiError(null);
  //       }
  //     }
  //   }, [apiError, success]);

  return (
    <>
      <div className="flex w-full flex-col mt-12 px-8">
        <div className="flex flex-col mb-7 sm:flex-row w-full justify-between">
          <button
            className="text-secondary flex px-6 py-3 w-56 items-center rounded-md border-2 mb-4 border-secondary"
            onClick={() => navigate(-1)}>
            <img src={arrowIcon} alt="add" draggable={false} className="mr-2" />
            <span>Volver a empresas</span>
          </button>
        </div>
        <p className="text-md sm:text-xl  font-semibold my-3 mb-5">
          Editar empresa <span className="text-secondary "></span>
        </p>

        <div className="">
          <p className="mb-2 font-semibold">Nombre de la empresa *</p>
          <Input
            type="text"
            value={companyName}
            onChange={(e) => setcompanyName(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && companyIsValid()) {
                updateCompany();
              }
            }}
          />
          <p className="mb-2 font-semibold">Participantes permitidos *</p>
          <Input
            type="number"
            value={allowedParticipants.toString()}
            onChange={(e) => setAllowedParticipants(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && allowedParticipantsIsValid()) {
                updateCompany();
              }
            }}
          />
          {success && <Notification type="success" message="Empresa actualizada" />}
          {apiError && <Notification type="error" message={apiError} />}
          <Button
            mx="mx-auto sm:mx-0"
            disabled={!companyIsValid() || !allowedParticipantsIsValid()}
            loading={loading}
            onClick={updateCompany}>
            Guardar cambios
          </Button>
        </div>
      </div>
    </>
  );
};

export default EditCompany;
