import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

//views
import Login from './views/public/Login';
import ResetPassword from './views/public/ResetPassword';
import ResetPasswordForm from './views/public/ResetPasswordForm';
import CreatePasswordForm from './views/public/CreatePasswordForm';
import NotFoundView from './views/public/NotFound';
import Dashboard from './views/private/Dashboard';
import MyAccount from './views/private/MyAccount';
import Participants from './views/private/Participants';
import Companies from './views/private/superAdmin/Companies';
import AdminUsers from './views/private/superAdmin/AdminUsers';
import EditCompany from './views/private/superAdmin/EditCompany';
import Stats from './views/private/Stats';

import { Page } from './components/Page/Page';

export default function Router() {
  const { isSuperAdmin } = useSelector((state) => state.user);

  return useRoutes([
    // Private views (use layout)
    {
      element: <Page />,
      children: [
        {
          path: '/',
          element: <Dashboard />
        },
        {
          path: '/account',
          element: <MyAccount />
        },
        {
          path: '/participants',
          element: <Participants />
        },

        isSuperAdmin === true && {
          path: '/companies',
          element: <Companies />
        },

        isSuperAdmin === true && {
          path: '/companies/:company/admin-users',
          element: <AdminUsers />
        },
        isSuperAdmin === true && {
          path: '/companies/:company/edit',
          element: <EditCompany />
        },
        {
          path: 'stats',
          element: <Stats />
        }
      ]
    },
    // Public views (no layout)
    {
      path: '/login',
      element: <Login />
    },
    {
      path: '/reset-password',
      element: <ResetPassword />
    },
    {
      path: '/reset-password-form',
      element: <ResetPasswordForm />
    },
    {
      path: '/create-password-form',
      element: <CreatePasswordForm />
    },
    {
      path: '*',
      element: <NotFoundView />
    }
  ]);
}
