import React from 'react';

// eslint-disable-next-line react/prop-types
const TextArea = ({ rows, onChange, placeholder, name, value, disabled }) => {
  return (
    <div>
      <div className="mt-1">
        <textarea
          rows={rows || 6}
          id="comment"
          className="block w-full resize-none rounded-md border-gray-300 shadow-sm focus:border-secondary focus:ring-secondary sm:text-sm"
          onChange={onChange}
          placeholder={placeholder}
          name={name}
          value={value}
          disabled={disabled}
        />
      </div>
    </div>
  );
};

export default TextArea;
