/* eslint-disable no-undef */
// import axios from 'axios';

// import { memoizedRefreshToken } from '../utils/refreshToken';

import axios from 'axios';
axios.create();
import { refreshToken } from '../utils/refreshToken';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
//   process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY
// )}`;
axios.defaults.timeout = 10000;

// Request interceptor for API calls
axios.interceptors.request.use(
  async (config) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.getItem(
        process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY
      )}`
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      const access_token = await refreshToken();
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + access_token;
      return axios(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default axios;

// axios.defaults.baseURL = process.env.REACT_APP_API_URL;
// axios.defaults.headers.common['Content-Type'] = 'application/json';
// axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem(
//   process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY
// )}`;
// axios.defaults.timeout = 10000;

// axios.interceptors.request.use(
//   async (config) => {
//     const accessToken = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY);

//     if (accessToken) {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }

//     return config;
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (response) => response,
//   async (error) => {
//     const config = error?.config;

//     if (error?.response?.status === 401 && !config._retry) {
//       config._retry = true;

//       const res = await memoizedRefreshToken();
//       if (res?.accessToken) {
//         config.headers.Authorization = `Bearer ${res.accessToken}`;
//         return axios(config);
//       }

//       return axios(config);
//     }

//     // catch cors error
//     if (typeof error === 'undefined') {
//       return Promise.reject(error);
//     }

//     return Promise.reject(error);
//   }
// );

// export default axios;
