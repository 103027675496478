import React from 'react';
import cx from 'classnames';
import Spinner from './Spinner';

const getSolidStyles = (color) => {
  let bg =
    'text-white bg-primary hover:bg-primary-800 disabled:bg-gray-200 disabled:text-gray-400 focus:ring-primary-500';
  switch (color) {
    case 'white':
      bg =
        'text-gray-700 bg-white border border-gray-200 hover:border-gray-500 disabled:bg-gray-50 disabled:text-gray-400 focus:border-gray-500 focus:ring-cobalt-500';
      break;
  }
  return `shadow-sm ${bg}`;
};

const getFlatStyles = (color) => {
  let border;
  switch (color) {
    case 'white':
      border = 'text-white';
      break;
  }
  return `border-0 ${border}`;
};

/* eslint-disable react/prop-types */
const Button = ({
  children,
  color = 'primary',
  onClick,
  type,
  disabled = false,
  loading,
  variant = 'solid',
  className,
  size = 'md',
  isFullWidth = false
}) => {
  const getContent = () => {
    if (loading) {
      return (
        <div className="flex items-center">
          <Spinner />
          {children}
        </div>
      );
    }

    return children;
  };

  return (
    <button
      disabled={disabled}
      type={type}
      onClick={onClick}
      className={cx([
        'inline-flex items-center rounded-md border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
        {
          [getSolidStyles(color)]: variant === 'solid',
          [getFlatStyles(color)]: variant === 'flat',
          ['px-3 py-2 text-sm']: size === 'sm',
          ['px-4 py-3 text-base']: size === 'md',
          ['px-5 py-4 text-lg']: size === 'lg',
          ['w-full justify-center']: isFullWidth
        },
        className
      ])}>
      {getContent()}
    </button>
  );
};

export default Button;
