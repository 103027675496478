import { useMediaQuery } from 'react-responsive';

export function useScreen() {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1280px)'
  });
  const isLaptop = useMediaQuery({
    query: '(min-width: 1024px)'
  });
  const isTablet = useMediaQuery({ query: '(min-width: 640px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 639px)' });

  return { isDesktop, isLaptop, isTablet, isMobile };
}
