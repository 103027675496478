import React from 'react';
import logo from '../assets/img/logo.png';

const Loader = () => {
  return (
    <div className="h-full w-full grid place-items-center">
      <img src={logo} alt="logo" className="w-52 animate-pulse" draggable={false} />
    </div>
  );
};

export default Loader;
