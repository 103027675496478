/* eslint-disable no-undef */
import axios from '../../../config/axiosInterceptor';
import React, { useEffect, useState } from 'react';
import { Button, Input, Notification } from '../../../components';

// eslint-disable-next-line react/prop-types
const AddCompany = ({ toggleModal, setSuccessCreatingCompany }) => {
  const [companyName, setcompanyName] = useState('');
  const [allowedParticipants, setAllowedParticipants] = useState(50);
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [success, setSuccess] = useState(false);

  const createCompany = async () => {
    setLoading(true);
    try {
      await axios.post('/admin/companies', {
        name: companyName,
        maxParticipants: allowedParticipants
      });
      setLoading(false);
      setSuccess(true);
    } catch (error) {
      if (process.env.REACT_APP_API_URL.includes('dev')) console.log(error);
      setLoading(false);
      setApiError(error.response.data.error || error.message);
    }
    setcompanyName('');
  };

  const companyIsValid = () => {
    return companyName.length > 0 && !loading;
  };

  const allowedParticipantsIsValid = () => {
    return allowedParticipants > 0 && !loading;
  };

  useEffect(() => {
    if (success) {
      toggleModal();
      setSuccessCreatingCompany(true);
      const timer = setTimeout(() => {
        setSuccessCreatingCompany(null);
      }, 3000);
      return () => clearTimeout(timer);
    }

    if (apiError) {
      if (companyName !== '') {
        setApiError(null);
      }
    }
  }, [apiError, success, companyName]);

  return (
    <div className="">
      <p className="text-secondary text-lg mb-7 font-semibold">Agregar nueva empresa</p>
      <p className="mb-2 font-semibold">Nombre de la empresa *</p>
      <Input
        type="text"
        value={companyName}
        onChange={(e) => setcompanyName(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && companyIsValid()) {
            createCompany();
          }
        }}
      />
      <p className="mb-2 font-semibold">Participantes permitidos *</p>
      <Input
        type="number"
        value={allowedParticipants}
        onChange={(e) => setAllowedParticipants(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter' && allowedParticipantsIsValid()) {
            createCompany();
          }
        }}
      />
      {apiError ? <Notification type="error" message={apiError} /> : null}
      <Button
        mx="mx-auto sm:mx-0"
        disabled={!companyIsValid()}
        loading={loading}
        onClick={createCompany}>
        Crear empresa
      </Button>
    </div>
  );
};

export default AddCompany;
