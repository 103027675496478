import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  setUserEmail,
  setUserName,
  setIsSuperAdmin,
  setUserCompany,
  setUserCompanyName
} from './store/slices/userSlice';
import { useNavigate, useLocation } from 'react-router-dom';
import { parseToken } from './utils/parseToken';
import Router from './routes';

/* eslint-disable no-undef */
const App = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN_STORAGE_KEY) || undefined;

  const { pathname } = useLocation();

  useEffect(() => {
    if (token) {
      const decodedToken = parseToken(token);
      dispatch(setIsSuperAdmin(decodedToken.isSuperAdmin));
      dispatch(setUserEmail(decodedToken.email));
      dispatch(setUserName(decodedToken.name));
      dispatch(setUserCompany(decodedToken.company));
      dispatch(setUserCompanyName(decodedToken.companyName));
      if (
        pathname === '/login' ||
        pathname === '/reset-password' ||
        pathname === '/reset-password-form'
      ) {
        navigate('/');
      }
    } else {
      if (
        pathname !== '/reset-password' &&
        pathname !== '/reset-password-form' &&
        pathname !== '/create-password-form'
      )
        navigate('/login');
    }
  }, [token]);
  return (
    <div>
      <Router />
    </div>
  );
};

export default App;
