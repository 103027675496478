import React, { useState } from 'react';

import { addParticipant } from '../../containers/participants/fetchingFunctions';
import { Notification, Button, TextArea } from '../../components';
import PropTypes from 'prop-types';

const AddParticipant = ({
  toggleAddParticipantsModal,
  setSuccess,
  toggleAddMultipleParticipantsModal,
  setNewParticipants,
  newParticipants
}) => {
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState('');
  const [isMoreThanOneParticipant, setIsMoreThanOneParticipant] = useState(false);

  const emailIsValid = () => {
    if (isMoreThanOneParticipant) {
      const emails = newParticipants;
      const validEmails = emails.filter((email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(email);
      });
      return validEmails.length === emails.length;
    }
    const re = /\S+@\S+\.\S+/;
    return re.test(newParticipants);
  };

  const handleUsersInput = (users) => {
    if (users.includes(',')) {
      const usersArray = users.split(',');
      setIsMoreThanOneParticipant(true);
      return usersArray;
    }
    setIsMoreThanOneParticipant(false);
    return users;
  };

  return (
    <div>
      <p className="text-secondary text-lg mb-7 font-semibold">Agregar participantes</p>
      <p className="my-4">
        Pega debajo los correos electrónicos de los participantes que quieres agregar separados por
        comas. Ej: mdiaz@quilmes.com.ar, nlopez@quilmes.com.ar
      </p>

      <TextArea
        rows={8}
        onChange={(e) => setNewParticipants(handleUsersInput(e.target.value))}
        value={newParticipants}
      />
      {apiError && <Notification type="error" message={apiError} />}
      <Button
        mx="mx-auto sm:mx-0"
        disabled={!emailIsValid()}
        loading={loading}
        onClick={() =>
          !isMoreThanOneParticipant
            ? addParticipant(
                setLoading,
                setSuccess,
                toggleAddParticipantsModal,
                setNewParticipants,
                setApiError,
                newParticipants
              )
            : toggleAddMultipleParticipantsModal()
        }>
        Agregar participantes
      </Button>
    </div>
  );
};

export default AddParticipant;

AddParticipant.propTypes = {
  toggleAddParticipantsModal: PropTypes.func,
  setSuccess: PropTypes.func,
  toggleAddMultipleParticipantsModal: PropTypes.func,
  newParticipants: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  setNewParticipants: PropTypes.func
};
